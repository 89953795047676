import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthorizationComponent } from './pages/authorization/authorization.component';
import { LogoutComponent } from './pages/authorization/logout.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'authorize',
    pathMatch: 'full',
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
    data: {title: 'Error'}
  },
  {
    path: 'authorize',
    component: AuthorizationComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: 'file-viewer',
    loadChildren: () =>
      import('./_metronic/shared/components/file-viewer/file-viewer.module').then((m) => m.FileViewerModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'ignore'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
