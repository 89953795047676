<button
  [disabled]="disabled"
  [attr.title]="title"
  class="btn {{
    extraClasses
  }} gap-8px d-flex align-items-center"
  [routerLink]="routerLink"
  [ngClass]="calculatedClass"
  [id]="btnId"
  [attr.data-kt-rotate]="rotateOnClicked"
  [attr.data-kt-toggle]="toggleEnabled"
  [attr.data-kt-toggle-state]="toggleState"
  [attr.data-kt-toggle-target]="toggleTarget"
  [attr.data-kt-toggle-name]="toggleAttr"
>
  <!-- THE ICON -->
  <ng-container *ngIf="!noIcon">
    <i *ngIf="isFontAwesome()"
      class="{{ iconType }} {{ iconName }} {{ iconClass }}"></i>

    <i *ngIf="isDuotone()"
      class="ki-{{ iconType }} ki-{{ iconName }} {{ iconClass }}">
      <span
        *ngFor="let i of [].constructor(pathsNumber); let idx = index"
        class="path{{ idx + 1 }}"
      ></span>
    </i>

    <i *ngIf="!isFontAwesome() && !isDuotone()"
      class="{{ iconType }} {{ iconName }} {{ iconClass }}"></i>
  </ng-container>

  <!-- RENDER DYNAMIC CONTENT -->
  <ng-content></ng-content>

  <!-- RENDER LABEL -->
  <div class="whitespace-nowrap"
    [ngClass]="labelClass"
    *ngIf="label">{{ label | translate }}</div>
</button>
