import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ToastService } from '../_metronic/shared/components/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { getErrorMessage } from '../_metronic/shared/utils/helper';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private toast: ToastService, private translate: TranslateService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const errorMessage = err.error?.response_schema?.response_message
          ? getErrorMessage(err, this.translate)
          : `Error: ${(err.message ?? err.status)}`;
        if (err.status >= 500) {
          this.toast.error(errorMessage);
        }
        return throwError(()=> err);
      })
    );
  }

}
