import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-base-content',
  templateUrl: './base-content.component.html',
  styleUrls: ['./base-content.component.scss']
})
export class BaseContentComponent implements OnDestroy {
  title: string = '';
  subscription: Subscription[] = [];
  preventLeavingPage: boolean = false;
  @Input() isLoading: boolean = false;
  constructor(private parentActiveRoute: ActivatedRoute) {
    this.title = this.parentActiveRoute?.snapshot?.data?.title;
  }

  canDeactivate(): boolean {
    return !this.preventLeavingPage;
  }

  ngOnDestroy(): void {
    this.subscription.forEach(s => s.unsubscribe())
  }
}
