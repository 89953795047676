<ng-template #commonModal>
  <div class="modal-content" [ngClass]="modalClass">
    <ng-content></ng-content>
    <div class="modal-header" *ngIf="commonModalConfig.header">
      <ng-content select="[ModalHeader]"></ng-content>
    </div>

    <div class="modal-body" *ngIf="commonModalConfig.body">
      <ng-content select="[ModalBody]"></ng-content>
    </div>
    <div class="modal-footer" *ngIf="commonModalConfig.footer">
      <ng-content select="[ModalFooter]"></ng-content>
    </div>
  </div>
</ng-template>
