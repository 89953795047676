import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { isEmptyString } from '../../utils/helper';
import icons from './icons.json';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {

  _disabled: boolean;
  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(val: boolean) {
    this._disabled = val;
  }
  @Input() label: string;
  @Input() routerLink: string;
  @Input() title: string;
  @Input() extraClasses: string;
  @Input() small: boolean = false;
  @Input() iconOnly: boolean = false;
  @Input() iconName: string;
  @Input() iconClass: string;
  @Input() iconType: string = "duotone";
  @Input() iconPosition: 'top'|'right'|'bottom'|'left' = 'left';
  @Input() btnId: string;
  @Input() colorPrimary: boolean = true;
  @Input() iconPrimary: boolean = false;
  @Input() rotateOnClicked: boolean = false;
  @Input() toggleState: string = '';
  @Input() toggleAttr: string = '';
  @Input() toggleEnabled: boolean;
  @Input() toggleTarget: string = '';
  @Input() outlined: boolean = false;
  @Input() labelClass: any;
  @Input() widthFitContent: boolean = true;

  pathsNumber: number = 0;
  get noIcon(): boolean { return !this.iconClass && !this.iconName; }
  _calculated: any = {};
  get calculatedClass(): any {
    this._calculated['btn-outline'] = this.outlined;
    this._calculated['btn-active-color-primary'] = this.colorPrimary;
    this._calculated['btn-active-icon-primary'] = this.iconPrimary;
    this._calculated['disabled'] = this.disabled;
    this._calculated['text-gray-400'] = this.disabled && this.iconType !== 'masked';
    this._calculated['masked'] = !this.isDuotone() && !this.isFontAwesome();
    this._calculated['btn-icon'] = this.iconOnly;
    this._calculated['btn-small'] = this.small;
    this._calculated['w-fit-content'] = this.widthFitContent;

    this._calculated[this.flexDirection] = true;
    return this._calculated;
  }

  get flexDirection(): string {
    switch(this.iconPosition){
      case 'top': return 'flex-column';
      case 'right': return 'flex-row-reverse';
      case 'bottom': return 'flex-column-reverse';
    }
    return 'flex-row';
  }
  ngOnInit() {
    if (this.iconType === 'duotone') {
      // @ts-ignore
      this.pathsNumber = icons[this.iconType + '-paths'][this.iconName] ?? 0;
    }
  }

  @HostBinding('style.display')
  get styleDisplay() {
    return 'contents';
  }


  isFontAwesome(): boolean {
    return this.iconType.startsWith('fa-');
  }

  isDuotone(): boolean {
    return !isEmptyString(this.iconName) && this.iconType === 'duotone';
  }

}
