import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MediaGroup } from 'src/app/types/enums/media.enum';
import { StatusTypes } from 'src/app/types/enums/other.enum';
import { IMediaInfo } from 'src/app/types/interfaces/common.interface';
import { getImageUrl, getThumbnailUrl, translateWithGroupPrefix } from '../../../utils/helper';
import { CommonModalComponent } from '../common-modal.component';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent extends CommonModalComponent {
  @Input() data: IMediaInfo[] = [];
  @Output() closeImageViewerModal = new EventEmitter<string>();

  @Input() currentPage: number = 0;

  getThumbnailUrl = getThumbnailUrl;
  MEDIA_STATUS = StatusTypes.MEDIA_STATUS;

  constructor(
    private translateService: TranslateService,
    private modal: NgbModal,
    ) {
    super(modal);
  }

  get currentMedia(): IMediaInfo {
    return this.data[this.currentPage];
  }

  get currentMediaTitle(): string {
    return translateWithGroupPrefix(this.translateService, this.currentMedia.title, 'MEDIA') ?? '';
  }

  isCurrentMediaVideo(): boolean {
    return this.currentMedia?.media_group === MediaGroup.VIDEO ||
      this.currentMedia?.file_upload_id?.file_name?.includes('.mp4') as boolean;
  }

  getImageSrc(media: IMediaInfo) {
    return getImageUrl(media);
  }

  previousMedia() {
    this.currentPage = (this.currentPage - 1 + this.data.length) % this.data.length;
  }

  nextMedia() {
    this.currentPage = (this.currentPage + 1) % this.data.length;
  }
}
