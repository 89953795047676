<div *ngFor="let toast of toasts">
  <ngb-alert [type]="toast.type" (close)="closeToast(toast)">
    <div class="d-flex flex-row align-items-center justify-content-start gap-15px">
      <div class="icon">
        <span *ngIf="toast.type==='success'" inlineSVG="../../../../../assets/icons/Content/icon-med-circle-check.svg"></span>
        <span *ngIf="toast.type==='danger'" inlineSVG="../../../../../assets/icons/Content/icon-med-circle-exclamation-mark.svg"></span>
      </div>
      <div [innerHTML]="toast.message | translate"></div>
    </div>
  </ngb-alert>
</div>