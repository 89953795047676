import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModalComponent } from '../common-modal.component';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['../common-modal.component.scss', './warning-modal.component.scss'],
})
export class WarningModalComponent extends CommonModalComponent {
  @Output() closeWarningModal = new EventEmitter<string>();
}
