import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Toast, ToastType } from './toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public subjectToast = new Subject<Toast>();
  public removeToast = new Subject<Toast['toastId']>();
  public clearAll = new Subject<boolean>;

  toastQueue: number[] = [];

  constructor(private translate: TranslateService) {}

  private generateToastQueue(): number {
    let lastNum;
    let newNum: number;
    if (this.toastQueue.length===0) {
      this.toastQueue.push(1);
      newNum = 1;
    } else {
      const total = this.toastQueue.length>0? this.toastQueue.length : 1;
      lastNum = this.toastQueue[total-1];
      newNum = lastNum+1;
      this.toastQueue.push(newNum);
    }
    return newNum;
  }

  // convenience methods
  success(message: string, params: any = {}) {
    this.prepareToast(ToastType.Success, message, params);
  }

  error(message: string, params: any = {}) {
    this.prepareToast(ToastType.Error, message, params);
  }

  info(message: string, params: any = {}) {
    this.prepareToast(ToastType.Info, message, params);
  }

  warn(message: string, params: any = {}) {
    this.prepareToast(ToastType.Warning, message, params);
  }

  loading() {
    this.toast(new Toast({ message: 'Loading', type: ToastType.Warning, toastId: this.generateToastQueue()}));
  }

  prepareToast(type: ToastType, message: string, params: any = {}) {
    message = this.translate.instant(message, params);
    this.toast(new Toast({ message, type, toastId: this.generateToastQueue() }));
  }
  // main toast method
  toast(toast: Toast) {
    this.subjectToast.next(toast);
    setTimeout(() => {
      this.clear(toast.toastId);
    }, 4000);
  }

  // clear toasts
  clear(toastId: number) {
    this.removeToast.next(toastId);
    this.toastQueue = this.toastQueue.filter((el)=>el!==toastId);
  }
}
export default { ToastService };
