import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseContentComponent } from '../base-content/base-content.component';
import { ToastService } from './toast.service';

export class Toast {
  type: ToastType;
  message: string;
  toastId: number;
  keepAfterRouteChange: boolean;
  constructor(init?: Partial<Toast>) {
    Object.assign(this, init);
  }
}

export enum ToastType {
  Success = 'success',
  Error = 'danger',
  Info = 'info',
  Warning = 'warning',
  Loading = 'light',
}

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent extends BaseContentComponent implements OnInit {
  toasts: Toast[] = [];

  constructor(
    private toastService: ToastService,
    private cdRef: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
  ) {
    super(activeRoute);
  }

  ngOnInit() {
    this.subscription = [
      this.toastService.subjectToast.subscribe((toast) => {
        if (toast.message) {
          this.toasts.push(toast);
          this.cdRef.detectChanges();
        }
      }),
      this.toastService.removeToast.subscribe((id) => {
        const target = this.toasts.find((el) => el.toastId === id);
        this.closeToast(target);
      }),
      this.toastService.clearAll.subscribe((val: boolean) => {
        if (val) this.toasts = [];
        this.cdRef.detectChanges();
      }),
    ];
  }

  removeToast(toast: Toast) {
    // remove specified toast from array
    this.toasts = this.toasts.filter((x) => x !== toast);
  }

  closeToast(toast: any) {
    const index = this.toasts.findIndex((el) => el.toastId === toast.toastId);
    this.toasts.splice(index, 1);
    this.cdRef.detectChanges();
  }
}
export default { ToastComponent };
