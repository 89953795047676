import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { CommonModalService } from '../_metronic/shared/components/common-modal/common-modal.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private modalService: CommonModalService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const spinneroff = request.params.get('spinneroff');
    request = request.clone({
      params: request.params.delete('spinneroff')
    });
    if (spinneroff === '1') {
      return next.handle(request);
    } else
    this.modalService.spinnerOn();
    return next.handle(request).pipe(
      finalize(() => {
        this.modalService.spinnerOff();
      })
    );
  }
}
