import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export interface CommonModalInterface {
  title?: string;
  text?: string;
  primaryBtnTxt?: string;
  secondaryBtnTxt?: string;
  validationField?: any[];
  size?: string;
  modalDialogClass?: string;
}

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonModalComponent implements OnInit {
  title?: string;
  text?: string;
  primaryBtnTxt?: string;
  secondaryBtnTxt?: string;
  size: NgbModalOptions['size'] = 'lg';
  validationField?: any[] = [];
  @Input() modalClass: any;
  @Input() config: CommonModalInterface;
  @Input() commonModalConfig: {
    header: boolean,
    body: boolean,
    footer: boolean
  } = {
    header: true,
    body: true,
    footer: true
  }

  ngOnInit(): void {
    this.title = this.config?.title;
    this.text = this.config?.text;
    this.primaryBtnTxt = this.config?.primaryBtnTxt;
    this.secondaryBtnTxt = this.config?.secondaryBtnTxt;
    this.validationField = this.config?.validationField;
    this.size = this.config?.size ?? 'lg'
  }

  @ViewChild('commonModal') private modalContent: TemplateRef<CommonModalComponent>;
  @Output() OnDismiss: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnOk: EventEmitter<any> = new EventEmitter<any>();

  private commonModal: NgbModalRef;

  constructor(private ngbModal: NgbModal) {}

  open(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.commonModal = this.ngbModal.open(this.modalContent, {size: this.size, modalDialogClass: this.config?.modalDialogClass});
      this.commonModal.result.then(resolve, resolve);
    });
  }

  async close(event: any): Promise<void> {
    this.commonModal.dismiss(event);
    this.OnClose.emit(event);
  }

  async ok(event: any): Promise<void> {
    this.commonModal.close(event);
    this.OnOk.emit(event);
  }
}
