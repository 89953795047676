export enum DocLicenseTabs {
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED',
}

export enum DocLicenseDocTypes {
  LOCATION_PERMIT = 'LOCATION_PERMIT',
  ENVIRONMENT_PERMIT = 'ENVIRONMENT_PERMIT',
  SKHP = 'SKHP',
  BA_TERA = 'BA_TERA',
  AS_BUILT_DRAWING = 'AS_BUILT_DRAWING',
  COMMISSIONING_REPORT = 'COMMISSIONING_REPORT',
  MDR = 'MDR',
}
