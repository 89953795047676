<div class="modal-content">
  <div class="modal-body">
    <div class="modal-title error">
      {{ title }}
    </div>
    <div class="modal-text">
      {{ text }}
    </div>
    <ng-container *ngIf="validationField">
      <div class="modal-text validation-field" *ngFor="let field of validationField">
        <span class="dotted" *ngIf="field.key !== ''">. </span>
        <span class="keyfield">{{field.key}}</span>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button *ngIf="primaryBtnTxt" type="button" class="btn btn-pill btn-secondary" (click)="closeErrorModal.emit('yes')">{{primaryBtnTxt}}</button>
      <button *ngIf="secondaryBtnTxt" type="button" class="btn btn-pill btn-secondary error" (click)="closeErrorModal.emit('close')">{{secondaryBtnTxt}}</button>
    </ng-container>
  </div>
</div>