import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { IApiResponseDetail, IApiResponseList, IDetailResponse, IFileUpload } from '../types/interfaces/common.interface';
import { IDistrictResponse, IProvinceResponse, ISubDistrictReponse } from '../types/interfaces/location.interface';
import { ENDPOINT } from 'src/app/types/constants/endpoint.constant';
import { ITerminalSupplyReponse } from '../types/interfaces/terimanal-supply.interface';
import { IResponseMap } from '../types/interfaces/map.interface';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/base.service';
import { displayResponseErrorIfAny } from '../_metronic/shared/utils/helper';
import { AppConstants } from '../types/constants/app.constant';
import { ResponseCode } from '../types/enums/response-code.enum';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../_metronic/shared/components/toast/toast.service';
import { ITankDetailData } from '../types/interfaces/terminal-receive.interface';
import { ProductType } from '../types/interfaces/mikrosite.interface';

@Injectable({
  providedIn: 'root'
})
export class SharedService extends BaseService {
  constructor(protected http: HttpClient, private toast: ToastService) {
    super(http);
  }
  
  getProvinces(): Observable<IProvinceResponse[]>{
    return this.getRequest(ENDPOINT.GEOLOCATION_PROVINCE)
    .pipe(
      map((el: IApiResponseList<IProvinceResponse>) => {
        return el.response_output.list.content || [];
      })
    );
  }

  getDistricts(province_id: string): Observable<IDistrictResponse[]>{
    return this.getRequest(ENDPOINT.GEOLOCATION_DISTRICTS+`/${province_id}`)
    .pipe(
      map((el: IApiResponseList<IDistrictResponse>) => {
        return el.response_output.list.content || [];
      })
    )
  }

  getSubDistricts(district_id: string): Observable<ISubDistrictReponse[]>{
    return this.getRequest(ENDPOINT.GEOLOCATION_SUBDISTRICT+`/${district_id}`)
    .pipe(
      map((el: IApiResponseList<ISubDistrictReponse>) => {
        return el.response_output.list.content || [];
      })
    )
  }

  getTerminalSupply(): Observable<ITerminalSupplyReponse[]>{
    return this.getRequest(ENDPOINT.TERMINAL_SUPPLY)
    .pipe(
      map((el: IApiResponseList<ITerminalSupplyReponse>) => {
        return el.response_output.list.content || [];
      })
    )
  }

  getProductsType(): Observable<ProductType[]> {
    return this.getRequest(ENDPOINT.PRODUCTS_TYPE).pipe(
      map((res: IApiResponseList<ProductType>) => {
        return res.response_output.list.content;
      })
    );
  }

  getTerminalTank(supplyId: string, typeId: string = ''): Observable<ITankDetailData[]>{
    return this.getRequest(ENDPOINT.TERMINAL_TANK + `?terminal-id=${supplyId}&type-id=${typeId}`)
    .pipe(
      map((el: IApiResponseList<ITankDetailData>) => {
        return el.response_output.list.content || [];
      })
    )
  }

  searchMapByCoordinate(latitude: number, longitude: number): Observable<IResponseMap> {
    const queryParams = {
      'query': `${latitude},${longitude}`,
      'subscription-key': environment.mapKey.subscriptionKey
    }
    return this.getRequest(ENDPOINT.MAP_AZURE_SEARCH, queryParams);
  }

  uploadFile(
    payload: FormData
  ): Observable<IDetailResponse<IFileUpload>['detail']> {
    return this.postRequestFile(ENDPOINT.FILE_UPLOAD, payload).pipe(
      catchError(err => {
        if (!displayResponseErrorIfAny(err.error, this.toast) && err.status === 413) {
          this.toast.error('UPLOAD_FILE.FILE_IS_TOO_LARGE');
        } else {
          this.toast.error('UPLOAD_FILE.SYSTEM_ERROR');
        }
        return throwError(() => err);
      }),
      map((res: IApiResponseDetail<IFileUpload>) => {
        if (res?.response_schema?.response_code === ResponseCode.SUCCESS) {
          this.toast.success('UPLOAD_FILE.SUCCESS');
        }
        return res.response_output.detail;
      })
    );
  }

  downloadFile(id: string): Observable<File> {
    return this.getRequestDownloadFile(ENDPOINT.FILE_UPLOAD+`/${id}/download`);
  }

  getFile(id: string): Observable<File> {
    return this.getRequestFile(ENDPOINT.FILE_UPLOAD+`/${id}/download`);
  }

  getDashboardContent(type: 'dashboard_inspection_tracker'|string): Observable<IDetailResponse<any>['detail']> {
    return this.getRequest(ENDPOINT.DASHBOARD_URL_TYPE(type)).pipe(
      map((res: IApiResponseDetail<any>) => {
        return res.response_output.detail;
      })
    );
  }
}
