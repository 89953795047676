import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AppConstants } from 'src/app/types/constants/app.constant';
import { IAuthProfile } from 'src/app/types/interfaces/auth-profile.interface';

@Component({
  selector: 'app-authorization',
  standalone: true,
  template: '',
})
export class AuthorizationComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getProfile();
  }

  getProfile() {
    const redirectUrl = this.activeRoute.snapshot.queryParams['redirect'] || AppConstants.WELCOME_URL;

    this.authService.authenticateUser().subscribe({
      next: (res: IAuthProfile) => {
        this.router.navigateByUrl(redirectUrl);
      },
      error: (err)=>{
        this.router.navigate(['error/401']);
      }
    });
  }
}
