<div class="d-flex flex-column flex-center flex-column-fluid h-100 w-100">
  <div class="d-flex flex-column flex-center text-center p-10">
    <div class="card card-flush  w-lg-650px py-5">
      <div class="card-body py-15 py-lg-20">
        <!--begin::Title-->
        <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
          {{ 'GUIDE.LOGGED_OUT' | translate }}
        </h1>
        <!--end::Title-->

        <!--begin::Illustration-->
        <div class="mb-3">
          <img src="" class="mw-100 mh-300px theme-light-show" alt="" />
          <img src="" class="mw-100 mh-300px theme-dark-show" alt="" />
        </div>
        <!--end::Illustration-->

        <!--begin::Link-->
        <div class="mb-0">
          <a routerLink="" class="btn btn-sm btn-primary">{{ 'COMMON.GOTO_LOGIN' | translate }}</a>
        </div>
        <!--end::Link-->
      </div>
    </div>
  </div>
</div>  
