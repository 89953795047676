// Bahasa Indonesia
export const localeId = {
  lang: 'id',
  data: {
    MENU: {
      WELCOME: 'Selamat Datang',
      DASHBOARD: 'Beranda',
      INSPECTION_TRACKER: 'Pelacakan Inspeksi',
      DASHBOARD_INSPECTION_TRACKER: 'Beranda Pelacakan Inspeksi',
      CSO_TERMINAL: 'Terminal CSO',
      DASHBOARD_CSO_TERMINAL: 'Beranda Terminal CSO',
      SKP_DISTRIBUTOR: 'SKP Distributor',
      DASHBOARD_SKP_DISTRIBUTOR: 'Beranda SKP Distributor',
      MICROSITE: 'Mikrosite',
      DOCUMENT_LICENSE: 'Lisensi Dokumen',
      LIST_TO_ASSIGN: 'Daftar Penugasan',
      INSPECTION: 'Perusahaan Inspeksi',
      INSPECTION_BOOK_MIGAS: 'Buku Inspeksi Lengkap',
      SIGN_BA_INSPECTION_DIST: 'Paraf Inspeksi BA (Distributor)',
      SIGN_BA_INSPECTION_EMLI: 'Paraf Inspeksi BA (EMLI)',
      FINDING_TICKET: 'Mencari Tiket',
      COI: 'Izin Keselamatan',
      SKHP_ANNUAL_TERRA_REPEAT: 'SKHP Terra Tahunan',
      COI_RENEWAL: 'Pembaharuan Izin Keselamatan',
      STOP_PROCESS_REQUEST: 'Setop Proses Permintaan',
      TERMINAL_LIFTING_REPORT: 'Laporan Muatan Terminal',
      TERMINAL_RECEIVE_REPORT: 'Laporan Penerimaan Terminal',
      TERMINAL_MONTHLY_REPORT: 'Laporan Bulanan Terminal',
      TANK_DATA: 'Data Tanki',
      DATA_MONITORING: 'Monitor Data',
      DATA_MONITORING_MIGAS: 'Data Cadangan Operational BPH Migas',
      DG_MIGAS: 'Laporan DG Migas',
      ACCESS_MANAGEMENT: 'Manajemen Akses',
      PREPARE_INSPECTION_BOOK: 'Pelaporan Buku Inspeksi',
      CUSTOMER_LIST: 'Daftar Customer',
      STOCK_CALCULATION_REPORT: 'Laporan Ketahanan',
      REPORT_BUILDER: 'Builder Laporan',
      REPORT_BUILDER_DISTRIBUTOR: 'Distributor',
      REPORT_BUILDER_CSO: 'CSO',
      REPORT_BUILDER_INSPECTION: 'Inspeksi'
    },
    PAGE: {
      TITLE: {
        ROLE_ACCESS: 'Akses Peran',
        DG_MIGAS: 'Laporan DG Migas',
        DEFINE_ROLE_ACCESS: 'Tentukan Akses Peran',
        EDIT_ROLE_ACCESS: 'Ubah Akses Peran',
        MICROSITE: 'Daftar Mikrosite',
        MICROSITE_DETAIL: 'Rincian Mikrosite',
        UPLOAD_MICROSITE: 'Unggah Mikrosite',
        CREATE_MICROSITE: 'Tambah Mikrosite',
        EDIT_MICROSITE: 'Ubah Mikrosite',
        DOCUMENT_LICENSE: 'Lisensi Dokumen',
        DOCUMENT_LICENSE_DETAIL: 'Rincian Lisensi Dokumen',
        UPLOAD_DOCUMENT_LICENSE: 'Unggah Lisensi Dokumen',
        LIST_TO_ASSIGN: 'Daftar Penugasan Mikrosite',
        LIST_TO_ASSIGN_UPLOAD: 'Unggah Daftar Penugasan Mikrosite',
        DETAIL_ASSIGN: 'Rincian Penugasan Inspeksi',
        INSPECTION_COMPANY_LIST: 'Daftar Perusahaan Inspeksi',
        INSPECTION_DATA_INPUT: 'Input Data Inspeksi',
        INSPECTION_BOOK_MIGAS_LIST: 'Laporan Hasil Pemeriksaan Keselamatan',
        INSPECTION_BOOK_MIGAS_DETAIL: 'Rincian Buku Inspeksi Migas',
        DETAIL_REVIEW: 'Tinjau Rincian',
        SIGN_BA_INSPECTION_LIST: 'Daftar Paraf Inspeksi BA',
        SIGNATURE_DOCUMENT_LIST: 'Daftar Paraf Dokumen',
        SITE_INFO: 'Info Site',
        COI_LIST: 'Daftar Izin Keselamatan',
        UPLOAD_COI: 'Unggah Izin Keselamatan',
        COI_DETAIL: 'Rincian Izin Keselamatan',
        COI_RENEWAL_LIST: 'Daftar Pembaruan Izin Keselamatan',
        PREPARE_INSPECTION_BOOK: 'Pelaporan Buku Inspeksi',
        DEFINE_DOCUMENT: 'Tentukan Dokumen',
        TERMINAL_LIFTING_REPORT: 'Laporan Muatan Terminal',
        TERMINAL_RECEIVE_REPORT: 'Laporan Penerimaan Terminal',
        INSPECTION_DATA_DETAIL: 'Rincian Data Inspeksi',
        UPLOAD_TERMINAL_DATA: 'Unggah Data Terminal',
        UPLOAD_TANK_DATA: 'Unggah Data Tanki',
        STOCK_CALCULATION_REPORT: 'Laporan Ketahanan',
        MONTHLY_STOCK_CALCULATION: 'Ketahanan Bulanan',
        QUARTERLY_STOCK_CALCULATION: 'Ketahanan Triwulanan',
        REPORT_BUILDER: 'Builder Laporan',
        REPORT_BUILDER_DISTRIBUTOR: 'Distributor',
        REPORT_BUILDER_CSO: 'CSO',
        REPORT_BUILDER_INSPECTION: 'Inspeksi'
      },
      LABEL: {
        ROLE_NAME: 'Nama Peran',
        ACCESS_MENU: 'Akses Menu',
        UPLOAD_FILE: 'Unggah File',
      },
      HI: 'Hai, {{name}}',
      LONG_TEXT: {
        WELCOME_MSG: 'Selamat datang di Integrated Mikrosite Management',
        WELCOME_MSG_DESC:
          'Aplikasi IM2 (Integrated Mikrosite Management) memudahkan pengguna mengunggah data dari berbagai sumber, seperti file, database, dan Gambar Dokumen. Aplikasi ini mendukung berbagai format data dan memastikan integritas data selama proses permintaan izin mikrosite',
      },
    },
    MODAL: {
      TITLE: {
        EM_SALES_ACCESS_MENU: 'Akses Menu Sales EM',
        CONFIRM_DELETE_IMAGE: 'Apakah Anda yakin ingin menghapus gambar?',
        CONFIRM_DELETE_VIDEO: 'Apakah Anda yakin ingin menghapus video?',
        CONFIRM_INSPECTION_DATA: 'Apakah Anda yakin ingin melanjutkan inspeksi data?',
        LEAVE_PAGE: 'Tinggalkan halaman dengan perubahan yang belum disimpan?',
        DELETE_CONFIRMATION: "Hapus {{title}}?",
      },
      TEXT: {
        CONFIRM_DELETE_IMAGE: 'Konfirmasi halaman ini akan menghapus gambar yang Anda pilih. \nApakah Anda ingin mengkonfirmasi atau membatalkan tindakan ini?',
        CONFIRM_DELETE_VIDEO: 'Konfirmasi halaman ini akan menghapus video yang Anda pilih. <br>Apakah Anda ingin mengkonfirmasi atau membatalkan tindakan ini?',
        CONFIRM_INSPECTION_DATA: `Setelah konfirmasi untuk melanjutkan data inspeksi, Anda akan diarahkan ke halaman lanjutkan data inspeksi untuk mengisi data inspeksi. \n Apakah Anda ingin mengkonfirmasi atau membatalkan tindakan ini?`,
        LEAVE_PAGE: 'Meninggalkan halaman ini akan menghapus semua perubahan yang belum disimpan. \nApakah Anda ingin meninggalkan halaman atau tetap di tindakan ini?',
        DELETE_CONFIRMATION: `{{title}} akan dihapus secara permanen. \nApakah Anda ingin menghapus atau membatalkan tindakan ini?`,
      }
    },
    BUTTON: {
      ASSIGN_TO: 'Tugaskan ke Inspeksi',
      REFRESH_DATA: 'Perbarui Data',
      LETS_GET_STARTED: "Mari memulai",
      SAVE: 'Simpan',
      CANCEL: 'Batal',
      UPLOAD_MIKROSITE: 'Unggah Mikrosite',
      NEW_MIKROSITE: 'Mikrosite Baru',
      DOWNLOAD_DATA_TABLE: 'Unduh Data Tabel',
      DOWNLOAD_DATA: 'Unduh Data',
      EDIT_MICROSITE: 'Ubah Mikrosite',
      EDIT_ASSIGN_INSPECTION: 'Ubah Penugasan Inspeksi',
      SUBMIT: 'Kirim',
      UPLOAD_DOCUMENT: 'Unggah Dokumen',
      DOWNLOAD: 'Unduh',
      CONFIRM: 'Konfirmasi',
      TANK_DATA: 'Data Tanki Baru',
      UPLOAD_TANK_DATA: 'Unggah Data Tanki',
      ADD_COMMENT: 'Tambah Komentar',
      ADD_REVISION: 'Tambah Revisi',
    },
    PAGINATION: {
      ENTRY_INFO: 'Menampilkan {{start}} sampai {{end}} dari {{total}} entri',
    },
    PLACEHOLDER: {
      SEARCH: 'Cari',
      SEARCH_ROLE_MENU: 'Cari Menu Peran',
      SEARCH_MIKROSITE: 'Cari Nama Site, Kota/Kabupaten, Provinsi',
      SEARCH_INSPECTION: 'Cari nama situs, kota/kabupaten, provinsi, perusahaan inspeksi...',
    },
    TABLE: {
      TH: {
        ROLE_MENU: 'MENU PERAN',
        ACCESS_MENU: 'AKSES MENU',
        ACTION: 'AKSI',
        LIST_OF_MENU: 'DAFTAR MENU',
        CREATE: 'BUAT',
        VIEW: 'LIHAT',
        UPDATE: 'UBAH',
        DELETE: 'HAPUS',
        MENU: 'MENU',
        ACCESS: 'AKSES',
        SITE_ID: 'SITE ID',
        SITE_NAME: 'NAMA SITUS',
        DISTRICT: 'KOTA/KABUPATEN',
        PROVINCE: 'PROVINSI',
        STATUS: 'STATUS',
        SITE_READY_TO_INSPECT: 'SITUS SIAP DIINSPEKSI',
        INSPECTION_STATUS: 'STATUS INSPEKSI',
        INSPECTION_PROGRESS: 'PROGRES INSPEKSI',
        DOCUMENT_STATUS: 'STATUS DOKUMEN',
        COORDINATE: 'KOORDINAT',
        INSPECTION_DATE: 'TANGGAL INSPEKSI',
        INSPECTION_COMPANY: 'PERUSAHAAN INSPEKSI',
        DOCUMENT_NAME: 'NAMA DOKUMEN',
        LOADING_COMPLETION_DATE: 'TANGGAL SELESAI MEMUAT',
        GOV_L: 'GOV (L)',
        GSV_L15: 'GSV (L 15)',
        GOV_L_TOTAL: 'GOV (L) TOTAL',
        GSV_L15_TOTAL: 'GSV (L 15) TOTAL',
        CUSTOMER_REF: 'REF PELANGGAN',
        TRIP_ID: 'ID TRIP',
        CUSTOMER_NAME: 'NAMA PELANGGAN',
        TANKI: 'TANKI',
        PRODUCT: 'PRODUK',
        VEHICLE_REGISTERED: 'KENDARAAN TEREGISTRASI',
        DRIVER_NAME: 'NAMA PENGEMUDI',
        LIFTING_MODA: 'MODE MUATAN',
        REMARK: 'CATATAN',
        DENSITY_15:'KEPADATAN.15',
        VCF: 'VCF',
        TEMP: 'TEMP.',
        VEHICLE: 'KENDARAAN',
        DRIVER: 'PENGEMUDI',
        DATE: 'TANGGAL',
        OPENING_STOCK: 'STOK AWAL',
        RECEIVE_IN: 'TERIMA MASUK',
        RECEIVE_IN_OTHER: 'TERIMA MASUK LAIN',
        CLOSING_STOCK: 'STOK AKHIR',
        SOUNDING_TANK: 'TANKI UKUR',
        SOUNDING_PIPE: 'PIPA UKUR',
        TOTAL_ACTUAL_STOCK: 'TOTAL STOK AKTUAL',
        GAIN_LOSS: 'UNTUNG/RUGI',
        SOUNDING_TIME: 'WAKTU UKUR',
        OPENING_STOCK_GOV: 'STOK AWAL GOV',
        RECEIVE_IN_GOV: 'TERIMA MASUK GOV',
        RECEIVE_IN_OTHER_GOV: 'TERIMA MASUK LAIN GOV',
        CLOSING_STOCK_GOV: 'STOK AKHIR GOV',
        SOUNDING_TANK_GOV: 'TANKI UKUR GOV',
        SOUNDING_PIPE_GOV: 'PIPA UKUR GOV',
        TOTAL_ACTUAL_STOCK_GOV: 'TOTAL STOK AKTUAL GOV',
        GAIN_LOSS_GOV: 'UNTUNG/RUGI GOV',
        SOUNDING_TIME_GOV: 'WAKTU UKUR GOV',
        OPENING_STOCK_GSV: 'STOK AWAL GSV',
        RECEIVE_IN_GSV: 'TERIMA MASUK GSV',
        RECEIVE_IN_OTHER_GSV: 'TERIMA MASUK LAIN GSV',
        CLOSING_STOCK_GSV: 'STOK AKHIR GSV',
        SOUNDING_TANK_GSV: 'TANKI UKUR GSV',
        SOUNDING_PIPE_GSV: 'PIPA UKUR GSV',
        TOTAL_ACTUAL_STOCK_GSV: 'TOTAL STOK AKTUAL GSV',
        GAIN_LOSS_GSV: 'UNTUNG/RUGI GSV',
        SOUNDING_TIME_GSV: 'WAKTU UKUR GSV',
        SKHP_STATUS: 'STATUS SKHP',
        BA_TRACKING: 'Pelacakan BA',
        BA_SAFETY_TRACKING: 'Pelacakan BA Keamanan',
        TW: 'TW',
        MONTH: 'BULAN',
        YEAR: 'TAHUN',
        DAYS_NUMBER: 'JUMLAH HARI',
        BBM_TYPE: 'JENIS BBM',
        DISTRIBUTION_VOL: 'VOL. PENYALURAN',
        STOCK_VOLUME_L: 'VOL. STOK [L]',
        CD_MONTH_DAYS: 'CD BULAN [days]',
        TERMINAL: 'TERMINAL',
        DAILY_CD: 'CD Harian',
        COMPANY: 'PERSEROAN',
        SUB_DISTRICT: 'KECAMATAN',
        LATITUDE: 'GARIS LINTANG',
        LONGITUDE: 'GARIS BUJUR',
        OWNERSHIP: 'KEPEMILIKAN',
        LOCATION_PERMIT: 'IZIN LOKASI',
        ENVIRONMENT_PERMIT_NUMBER: 'NO. IZIN LINGKUNGAN',
        ENVIRONMENT_PERMIT: 'IZIN LINGKUNGAN',
        COI: 'IZIN KESELAMATAN',
        COI_NUMBER: 'NO. IZIN KESELAMATAN',
        OPERATIONAL_STATUS: 'STATUS OPERASIONAL',
        TANK_NUMBERS: 'JLH. TANGKI',
        TANK_CAPACITY: 'KAPASITAS TANGKI',
        DISPENSER_NUMBERS: 'JLH. DISPENSER',
        DISTRIBUTION_AREA: 'WILAYAH PENYALURAN',
        REPORTED: 'DILAPORKAN',
        PARTNER_NAME: 'NAMA MITRA',
        DISTRIBUTOR_ADDRESS: 'ALAMAT DISTRIBUTOR',
        TBBM_SUPLY: 'TBBM PEMASOK',
        STARTED_OPERATION: 'MULAI BEROPERASI',
        DOWNLOAD_FILE: 'DOWNLOAD FILE',
        DISTRIBUTOR: 'DISTRIBUTOR',
        STORAGE_NAME: 'Nama Penyimpanan',
        MINI_DISTRIBUTOR_NUMBER: 'Nomor Penyalur Mini', // this specific Penyalur is not Distributor
        MINI_DISTRIBUTOR_ADDRESS: 'Alamat Penyalur Mini', // this specific Penyalur is not Distributor
        MITRA_NAME: 'Nama Mitra',
        MITRA_COMPANY_TYPE: 'Jenis Perseroan Mitra',
        NPWP: 'NPWP',
        TDP_NIB: 'TDP_NIB',
        COMMISSIONER: 'Nama Komisaris',
        COMMISSIONER_NPWP: 'NPWP Komisaris',
        DIRECTOR: 'Nama Direksi',
        DIRECTOR_NPWP: 'NPWP Direksi',
        DISTRICT_SUB_DISTRICT: 'Kabupaten/Kota Kecamatan',
        PARTNERSHIP_CONTRACT_NUMBER: 'Nomor Kontrak Kerjasama',
        PARTNERSHIP_CONTRACT_DATE: 'Tanggal Kontrak Kerjasama',
        PARTNERSHIP_CONTRACT_END_DATE: 'Tanggal Akhir Kontrak Kerjasama',
        PARTNERSHIP_CONTRACT_FILE: 'File Kontrak Kerjasama',
        PARTNERSHIP_CONTRACT_ADDENDUM_NUMBER: 'Nomor Addendum Kontrak Kerjasama',
        PARTNERSHIP_CONTRACT_ADDENDUM_DATE: 'Tanggal Addendum Kontrak Kerjasama',
        PARTNERSHIP_CONTRACT_ADDENDUM_FILE: 'File Addendum Kontrak Kerjasama',
        OWNERSHIP_STATUS: 'Status Kepemilikan',
        LOCATION_PERMIT_NUMBER: 'Nomor Izin Lokasi',
        LOCATION_PERMIT_FILE: 'File Izin Lokasi',
        ENVIRONMENT_PERMIT_FILE: 'FILE IZIN LINGKUNGAN',
        COI_FILE: 'FILE IZIN KESELAMATAN',
        PENYALURAN_HARIAN_L: 'PENYALURAN_HARIAN_L',
        JENIS_BBM: 'JENIS_BBM',
        VOLUME_STOK_L: 'VOLUME_STOK_L',
        TANGGAL: 'TANGGAL',
        NAMA_PENYIMPANAN: 'NAMA_PENYIMPANAN',
        NEED_REVISION: 'Diperlukan Revisi',
      },
    },
    UPLOAD_FILE: {
      TOO_LARGE: 'File dokumen harus kurang dari {{size}} MB',
      FILE_IS_TOO_LARGE: 'Ukuran file terlalu besar',
      INVALID_TYPE: 'Dokumen yang diunggah harus dalam format {{types}}',
      SYSTEM_ERROR: 'Pengunggahan dokumen gagal karena masalah sistem, silakan coba lagi',
      SUCCESS: 'Dokumen Berhasil Diunggah',
    },
    TOAST: {
      SUCCESS: {
        DRAFT: 'Berhasil Menyimpan Draf',
        SUBMIT: 'Berhasil Menyimpan',
        DOWNLOAD: 'Berhasil Mengunduh',
        EDIT: 'Berhasil Mengedit',
        DELETE: 'Berhasil Menghapus',
      },
      FAILED: {
        DRAFT: 'Gagal Menyimpan Draf, Silakan Coba Lagi',
        SUBMIT: 'Gagal Menyimpan, Silakan Coba Lagi',
        DOWNLOAD: 'Gagal Mengunduh, Silakan Coba Lagi',
        EDIT: 'Gagal Mengedit, Silakan Coba Lagi',
        DELETE: 'Gagal Menghapus, Silakan Coba Lagi',
        REVISION_MUST_BE_RESOLVED_FIRST: 'Revisi harus diselesaikan terlebih dulu',
      }
    },
    ACCESS_MANAGEMENT: {
      EDIT_ROLE: 'Edit Akses Peran',
    },
    MICROSITE_FORM: {
      SITE_ID: 'Masukkan Site ID',
      SITE_NAME: 'Masukkan Nama Site',
      OWNERSHIP: 'Masukkan Nama Kepemilikan',
      PROVINCE: 'Masukkan Provinsi ...',
      DISTRICT: 'Masukkan Kota/Kabupaten ...',
      DISTRICT_DESC:
        'Tentukan Provinsi terlebih dahulu sebelum menentukan Kota/Kabupaten',
      SUB_DISTRICT: 'Masukkan Kecamatan ...',
      SUB_DISTRICT_DESC:
        'Tentukan Kota/Kabupaten terlebih dahulu sebelum menentukan Kecamatan',
      SITE_ADDRESS: 'Masukkan detail alamat',
      PIN_POINT_LOCATION:
        'Anda dapat menentukan Titik Pin setelah menentukan Provinsi, Kota/Kabupaten, Kecamatan',
      CAPACITY_DATA: 'Masukkan Kapasitas Tangki',
      OPERATION_STATUS: 'Pilih Status Operasi',
      DISTRIBUTION_AREA: 'Masukkan area distribusi di sini...',
      TERMINAL_SUPPLY: 'Pilih Terminal Pemasok',
      LAND_COORDINATE_PER_LEASE_AGREEMENT:
        'Masukkan Koordinat Tanah per Perjanjian Sewa',
      LOCATION_PERMIT_ISSUER: 'Masukkan Penerbit Izin Lokasi',
      ENVIRONMENT_PERMIT_ISSUER: 'Masukkan Penerbit Izin Lingkungan',
      ADDRESS: 'Masukkan Alamat',
      NEXT: 'Lanjut',
      BACK: 'Mundur',
      CANCEL: 'Batal',
      SAVE_AS_DRAFT: 'Simpan Sebagai Draft',
      SET_PIN_POINT: 'Tentukan Pin Point',
      SELECT_PROVINCE: 'Tentukan Provinsi',
      SELECT_DISTRICT: 'Tentukan Kota/Kabupaten'
    },
    MICROSITE_DETAIL: {
      SITE_ID: 'Site ID',
      SITE_NAME: 'Nama Site',
      OWNERSHIP: 'Kepemilikan',
      SITE_DATA: 'Data Site',
      LEASE: 'Sewa',
      DOCUMENT_DATA: 'Data Dokumen',
      SITE_ADDRESS: 'Alamat Site',
      SUB_DISTRICT: 'Kecamatan',
      DISTRICT: 'Kota/Kabupaten',
      PROVINCE: 'Provinsi',
      PIN_POINT_LOCATION: 'Lokasi Titik Pin',
      COORDINATE: 'Koordinat',
      COORDINATE_LAT: 'Garis Lintang',
      COORDINATE_LONG: 'Garis Bujur',
      SEE_MAP_LOCATION: 'Lihat Peta Lokasi',
      SITE_OPERATIONAL_DATE: 'Tanggal Operasional Site',
      OPERATIONAL_DATE: 'Tanggal Operasional',
      OPERATION_STATUS: 'Status Operasi',
      CAPACITY_DATA: 'Kapasitas Tangki',
      CAPACITY_DATA_KL: 'Kapasitas Tangki (Kilo Liter)',
      FUELS_TYPE: 'Tipe Bahan Bakar',
      PRODUCT_TYPE: 'Tipe Produk',
      TERMINAL_SUPPLY: 'Terminal Pemasok',
      DISTRIBUTION_AREA: 'Area Distribusi',
      LAND_LEASE_AGREEMENT_NUMBER: 'Nomor Perjanjian Sewa Tanah',
      LAND_COORDINATE_PER_LEASE_AGREEMENT: 'Koordinat Tanah per Perjanjian Sewa',
      LAND_LEASE_START_DATE: 'Tanggal Mulai Sewa Tanah',
      LAND_LEASE_END_DATE: 'Tanggal Akhir Sewa Tanah',
      LOCATION_PERMIT: 'Izin Lokasi',
      ENVIRONMENT_PERMIT: 'Izin Lingkungan',
      ENVIRONMENT_PERMIT_ISSUER: 'Penerbit Izin Lingkungan',
      DOCUMENT_NUMBER: 'Nomor Dokumen',
      DOCUMENT_DATE: 'Tanggal Dokumen',
      LOCATION_PERMIT_ISSUER: 'Penerbit Izin Lokasi',
      DOWNLOAD_FILE: 'Unduh File',
      ID_SITE: 'Site ID',
      NAME_SITE: 'Nama Site',
      DOCUMENT_STATUS: 'Status File',
    },
    MICROSITE_UPLOAD: {
      DATA_PREVIEW: 'Pratinjau Data {{name}}',
      GUIDE: {
        MUST_BE_CONSIDER: 'Perlu diperhatikan',
        FILE_MUST_BE: 'File harus berupa {{mime}}',
        MAKE_SURE_THE_DATA_IS_MATCH_WITH_FIELD:
          'Pastikan data sesuai',
        MAKE_SURE_THE_FIELD_IS_NOT_EMPTY: 'Pastikan data tidak kosong',
      },
      TABLE: {
        STATUS: 'Status',
        OPERATION_STATUS: 'Status Operasi',
        OPERATIONAL_DATE: 'Tanggal Operasional',
        DISTRIBUTION_AREA: 'Area Distribusi',
        SITE_NAME: 'Nama Site',
        OWNERSHIP: 'Kepemilikan',
        PROVINCE: 'Provinsi',
        DISTRICT: 'Kota/Kabupaten',
        SUB_DISTRICT: 'Kecamatan',
        SITE_ADDRESS: 'Alamat Site',
        SITE_ID: 'SITE ID',
        COORDINATE: 'Koordinat',
        COORDINATE_LAT: 'Koordinat Garis Lintang',
        COORDINATE_LONG: 'Koordinat Garis Bujur',
        CAPACITY_DATA: 'Kapasitas Tangki',
        FUEL_TYPE: 'Tipe Bahan Bakar',
        PRODUCT_TYPE: 'Tipe Produk',
        TERMINAL_SUPPLY: 'Terminal Pemasok',
        LAND_LEASE_AGREEMENT_NUM: 'Nomor Perjanjian Sewa Tanah',
        LAND_LEASE_AGREEMENT_COORDINATE: 'Koordinat Perjanjian Sewa Tanah',
        LAND_LEASE_START_DATE: 'Tanggal Mulai Sewa Tanah',
        LAND_LEASE_END_DATE: 'Tanggal Akhir Sewa Tanah',
        LOCATION_DOC_NUMBER: 'Nomor Dokumen Lokasi',
        LOCATION_PERMIT_ISSUER: 'Penerbit Izin Lokasi',
        LOCATION_DOC_DATE: 'Tanggal Dokumen Lokasi',
        LOCATION_ADDRESS: 'Alamat Lokasi',
        ENV_DOC_NUMBER: 'Nomor Dokumen Lingkungan',
        ENV_PERMIT_ISSUER: 'Penerbit Izin Lingkungan',
        ENV_NAME_SITE: 'Nama Lingkungan Site',
        ENV_DOC_DATE: 'Tanggal Dokumen Lingkungan',
        ENV_ID_SITE: 'Site ID Lingkungan',
        ENV_ADDRESS: 'Alamat Lingkungan',
      },
    },
    MEDIA: {
      MICROSITE_FUEL_GENERAL_VIEW: 'Tampak Umum Bahan Bakar Mikrosite',
      DISPENSER_AREA: 'Kondisi Area Dispenser',
      NAME_PLATE_DISPENSER: 'Plat Nama Dispenser',
      TERA_CALIBRATION_STICKER: 'Stiker Tera/Kalibrasi',
      FUEL_TANK_NUMBER: 'Nomor Tanki Bahan Bakar',
      FUEL_TANK_NAME_PLATE: 'Plat Nama Tanki Bahan Bakar',
      GROUNDING_CONNECTION: 'Koneksi Pembumian',
      DISPENSER_MOTOR_AND_PUMP_CONDITION: 'Motor Dispense dan Kondisi Pompa',
      TANK_UL_MANUFACTURER_NAME_PLATE: 'Plat Nama Produsen UL Tanki',
      MID: 'MID',
      OIL_CATCHER: 'Penyaring Minyak',
      APAR_DI_AREA_DISPENSER: 'APAR di Dispenser Area',
      APAR_DI_AREA_BENGKEL_DAN_TOKO: 'APAR di Bengkel & Toko Area',
      TITIK_BERKUMPUL: 'Titik Berkumpul',
      WARNING_SIGN: 'Tanda Peringatan',
      LAMPU_PENERANGAN: 'Lampu Penerangan',
      ERP: 'ERP',
      ABOVE_TANK: 'Tanki Atas',
      CLOSE_UP_ON_INDOSTATION: 'Foto Jarak Dekat Indostation',
      ELECTRICAL_PANEL_ROUTER_AND_CONTROLLER: 'Panel Elektrik, Router & Pengendali',
      PLN_METER_INCL_TOILET: 'Meteran PLN, Termasuk WC',
      CONCRETE_BOX_FOR_USED_BOTTLES: 'Kotak Semen untuk Botol Bekas',
      INFILTRATION_WELL: 'Sumur Infiltrasi (Jika ada)'
    },
    MEDIA_STATUS: {
      MAJOR: 'Temuan Besar',
      MINOR: 'Temuan Kecil'
    },
    MICROSITE_STATUS: {
      AWAIT_DOC_UPLOAD: 'Menunggu Unggahan Dok',
      ASSIGNED_INSPECTION: 'Inspeksi Tertugas',
      INSPECTION_PROCESS: 'Proses Inspeksi',
      COI_ISSUED: 'Izin Keselamatan Diterbitkan',
      COI_PROCESS: 'Proses Izin Keselamatan',
      COMPLETE_ESSENTIAL_DOC: 'Dokumen Penting Lengkap',
      NEED_DATA_REVISION: 'Butuh Revisi Data',
      NEED_DOCUMENT_REVISION: 'Butuh Revisi Dokumen',
      BA_INSPECTION: 'Inspeksi Selesai',
      SUBMITTED: 'Terkirim',
      DRAFT: 'Draf',
      APPROVED: 'Disetujui',
      REJECTED: 'Ditolak',
    },
    OPERATION_STATUS: {
      CIVIL_WORK: 'Pekerjaan Sipil',
      READY_FOR_DEPLOYMENT: 'Terkirim',
      UNIT_DEPLOYED: 'Unit Diterapkan',
      TERA_FINISHED: 'TERA Selesai',
      HOLD: 'Hold',
      DROP: 'Drop',
      OPERATIONAL: 'Operasional',
      READY_TO_INSPECT: 'Siap untuk Diinspeksi',
      CLOSED: 'Tutup',
    },
    INSPECTION_STATUS: {
      UNASSIGNED: 'Belum Ditugaskan',
      ASSIGNED_INSPECTION: 'Inspeksi Tertugas',
      INSPECTION_PROCESS: 'Proses Inspeksi',
      INSPECTION_COMPLETED: 'Inspeksi Selesai',
    },
    COI_STATUS: {
      COMPLETE: 'Lengkap',
      NOT_COMPLETE: 'Belum Lengkap',
      EXPIRED: 'Kedaluwarsa',
      EXPIRED_SOON: 'Akan Kedaluwarsa',
    },
    MICROSITE_TABS: {
      SUBMITTED: 'Terkirim',
      DRAFT: 'Draf',
      REJECTED: 'Butuh Revisi',
      APPROVED: 'COI Diterbitkan',
    },
    INSPECTION: {
      TAB: {
        FOR_REVIEW: 'Untuk Tinjauan',
        CONTINUE: 'Lanjutkan Pemeriksaan Data',
        COMPLETE: 'Data Lengkap',
        REVISION: 'Diperlukan Revisi',
        MICROSITE_DATA: 'Data Mikrosite',
        INSPECTION_DATA: 'Data Inspeksi'
      },
      FORM: {
        INSPECTION_STATUS: 'Status Inspeksi',
        INSPECTION_ASSIGN_DATE: 'Tanggal Penugasan Inspeksi',
        INSPECTION_DATE: 'Tanggal Inspeksi',
        INSPECTION_BOOK_UPLOAD_DATE: 'Tanggal Unggah Buku Inspeksi',
        DISPENSER_ID: 'ID Dispenser',
        FUEL_UNIT_ID: 'ID Unit Bahan Bakar',
        UL_MENUFACTURER_ID: 'ID Pabrik UL',
        NUMBER_OF_DISPENSER: 'Jumlah Dispenser',
        NUMBER_OF_TANK: 'Jumlah Tangki',
        TANK_CAPACITY: 'Kapasitas Tangki',
        VOLUME_UNIT: 'Satuan Volume: Kilo Liter',
        MANDAROTY_IMG: 'Gambar Wajib',
        ADDITIONAL_IMG: 'Gambar Tambahan',
        ADDITIONAL_VIDEO: 'Video Tambahan',
        OTHER_ADDITIONAL_IMG: 'Gambar Tambahan Lainnya',
        MIGAS_OFFICER: "Petugas Migas",
        INSPECTOR: "Inspektur",
        ASSIGNMENT_LETTER_TO_MIGAS: "Surat Penugasan ke Migas",
        APPOINTMENT_LETTER_FROM_MIGAS: "Surat Penunjukkan dari Migas",
        UPLOAD_APPOINTMENT_LETTER_FROM_MIGAS: "Unggah Surat Penunjukkan dari Migas",
        UPLOAD_ASSIGNMENT_LETTER_TO_MIGAS: "Unggah Surat Penugasan ke Migas",
        UPLOAD_BA_INSPECTION: "Unggah Inspeksi BA",
        UPLOAD_BA_SAFETY_INSPECTION: "Unggah Inspeksi Keamanan BA",
        INVOICE_NO: 'Nomor Faktur',
        INVOICE_DATE: 'Tanggal Faktur',
        UNIT_RATE: 'Tarif Unit (IDR)',
        INVOICE_VALUE: 'Nilai Faktur',
        PO_NUMBER: 'Nomor PO',
        COI_ACCELERATE: 'Izin Keselamatan Akselerasi Klaim IC',
        PO_CODE: 'Kode PO',
        PO_PROGRESS: 'Progres PO',
        PO_DESC: 'Deskripsi Kode PO',
      },
      ERROR_FUEL_UNIT_DUPLICATE: 'ID Bahan Bakar yang diinput sudah ada di Data Inspeksi sebelumnya',
      TICKET_CATEGORY: 'Kategori Tiket',
      CREATE_TICKET_IMAGE: 'Buat tiket untuk gambar ini',
      CREATE_TICKET_VIDEO: 'Buat tiket untuk vidio ini',

      // PROBABLY UNUSED
      FOR_REVIEW: 'ulasan',
      CONTINUE: 'lanjutan pemeriksaan',
      COMPLETE: 'data lengkap',

      CONFIRMATION_INSPECTION: 'Lanjutkan ke Input Data Konfirmasi?',
      CONFIRMATION_APPROVE: 'Lanjutkan Konfirmasi Data',
      CONFIRMATION_REJECT: 'Tolak Konfirmasi',
      REJECTION_TYPE: 'Jenis Penolakan',
      REJECTION_NOTE: 'Berikan Catatan',
      DOCUMENT_LETTER: 'Surat Dokumen',
      INSPECTION_DATA: 'Data Inspeksi',
      SITE_IMAGES: 'Gambar Site',
      BA_INSPECTION: 'Inspeksi BA',
      BA_SAFETY_INSPECTION: 'Inspeksi Keamanan BA'
    },
    COI: {
      FORM: {
        COI_DATE: "Tanggal Izin Keselamatan",
        COI_NUMBER: "Nomor Izin Keselamatan",
        COI_EXPIRED_DATE: "Tanggal Kedaluwarsa",
        COI_STATUS: "Status Izin Keselamatan",
        COI_ISSUED_DATE: "Tanggal Penerbitan Izin Keselamatan",
        COI_ISSUED_NUMBER: "Nomor Penerbitan Izin Keselamatan",
        BA_SENT_MIGAS: "BA Dikirim ke Migas",
      },
      EDIT_COI: "Ubah Izin Keselamatan",
      UPLOAD_COI: "Unggah Izin Keselamatan",
      COI_HISTORY: "Riwayat COI",
    },
    COI_RENEWAL: {
      EXPIRED_SOON: 'Akan Kedaluwarsa',
      DOC_EXPIRED: 'Dokumen Kedaluwarsa',
      INFORMATION_COI_RENEWAL: 'Periksa data mikrosite secara menyeluruh sebelum melanjutkan perpanjangan',
      VIEW_FOR_RENEWAL: 'Lihat untuk Perpanjangan',
      CONFIRMATION_RENEWAL: 'Apakah Anda ingin melanjutkan proses perpanjangan?',
      CONTINUE_PROCESS: 'Lanjutkan Proses',
    },
    PREPARE_INSPECTION_BOOK: {
      UNDEFINED: 'Tidak terkirim', // UNDEFINED was the first design, it was later changed to UNSENT
      DEFINED: 'Terkirim',
      DOCUMENT_TRACKING: 'Pelacakan Dokumen',
      PIB_DETAIL: 'Menyiapkan Detail Buku Inspeksi',
      SEND_TO_MIGAS: 'Kirim ke Migas',
      INSPECTION_EMLI: 'Inspeksi kirim ke EMLI',
      EMLI_MIGAS: 'EMLI kirim ke DG Migas',
      DOWNLOAD_ALL_DOC: 'Unduh Semua Dokumen',
      COI_ISSUED: 'Izin Keselamatan Diterbitkan',
      FORM: {
        CODE: 'Kode',
        DOCUMENT_NAME: 'Nama Dokumen',
        DOCUMENT_SOURCE: 'Sumber Dokumen',
        DOCUMENT_FILE: 'File Dokumen',
        ACTION: 'Aksi',
      },
    },
    DOCUMENT_SOURCE: {
      DOCUMENT_TEMPLATE: 'Template Dokumen',
      MANUAL_UPLOAD: 'Unggah Manual',
      FROM_SYSTEM: 'Dari Sistem',
      FROM_SKHP_DOCUMENT: 'Dari Dokumen SKHP',
    },
    TERMINAL_RECEIVE_REPORT: {
      TERMINAL_NAME: 'Nama Terminal',
      ACTIVE: 'Aktif',
      EDIT_TANK: 'Ubah Tanki',
      NEW_TANK: 'Tangki Baru',
      TANK_STATUS: 'Status Tangki',
      TANK_NAME: 'Nama Tangki',
      RECEIVE_IN: 'Terima Masuk',
      RECEIVE_IN_OTHER: 'Terima Masuk Lainnya',
      RECEIVE_IN_TOTAL_GSV: 'Total Terima Masuk GSV',
      RECEIVE_IN_TOTAL_GOV: 'Total Terima Masuk GOV',
      RECEIVE_IN_OTHER_TOTAL_GSV: 'Total Terima Masuk Lainnya GSV',
      RECEIVE_IN_OTHER_TOTAL_GOV: 'Total Terima Masuk Lainnya GOV',
      SOUNDING_TANK: 'Tangki Ukur',
      SOUNDING_PIPE: 'Pipa Ukur',
      TOTAL_ACTUAL_STOCK: 'Total Stok Aktual',
      GAIN_LOSS: 'Untung/Rugi',
      SOUNDING_TIME: 'Waktu Ukur',
    },
    DATA_MONITORING: {
      OPENING_STOCK: 'Stok Awal',
      SUPPLY_TOTAL: 'Total Pasokan',
      STOCK_TRANSFER_TOTAL: 'Total Transfer Stok',
      CLOSING_STOCK: 'Stok Akhir',
      AVERAGE_CLOSING_STOCK: 'STOK AKHIR RATA-RATA',
      END_OF_CURRENT_MONTH: 'Akhir Bulan Ini',
      SALES_TOTAL: 'Total Penjualan',
      GAIN_LOSS_TOTAL: 'Total Untung/(Rugi)',
      MONTHLY_CD: 'CD Bulanan',
      QUARTERLY_CD: 'CD Triwulan',
      PRODUCT_NAME: 'Nama Produk',
      STORAGE_NAME: 'Nama Penyimpanan',
    },
    DOCUMENT_LICENSE: {
      LABEL: {
        OPERATIONAL_DATE: 'Tanggal Operasional',
        SITE_ID: 'Site ID',
        SITE_NAME: 'Nama Site',
        SITE_ADDRESS: 'Alamat Site',
        SITE_READY_DATE: 'Site Siap Diinspeksi',
        DOCUMENT_DATE: 'Tanggal Dokumen',
        DOCUMENT_NUMBER: 'Nomor Dokumen',
        DOCUMENT_VALIDITY: 'Masa Berlaku Dokumen',
        STATUS_DOCUMENT: 'Status Dokumen',
        UPLOAD_FILE: 'Unggah File',
        LOCATION_PERMIT: 'Izin Lokasi',
        LOCATION_PERMIT_ISSUER: 'Penerbit Izin Lokasi',
        ENVIRONMENT_PERMIT: 'Izin Lingkungan',
        ENVIRONMENT_PERMIT_ISSUER: 'Penerbit Izin Lingkungan',
        SKHP: 'SKHP',
        SKHP_ISSUER: 'Penerbit SKHP',
        BA_TERA_ISSUER: 'Penerbit Berita Acara Tera',
        BA_TERA: 'Berita Acara Tera',
        BA_TERA_DATE: 'Tanggal Berita Acara Tera',
        AS_BUILT_DRAWING: 'Gambaran Teknis Bangunan',
        DRAWING_NUMBER: 'Nomor Gambar',
        COMMISSIONING_REPORT: 'Laporan Pemeriksaan',
        CONTRACTOR_NAME: 'Nama Kontraktor',
        MDR: 'MDR',
        DOCUMENT_FILE: 'File Dokumen',
        UPLOAD_DATE: 'Tanggal Unggah',
        ID_SITE: "Site ID",
        NAME_SITE: "Nama Site",
        ACTION: "Aksi",
        LOCATION_PERMIT_HISTORY: 'Riwayat Izin Lokasi',
        ENVIRONMENT_PERMIT_HISTORY: 'Riwayat Izin Lingkungan',
        SKHP_HISTORY: 'Riwayat SKHP',
        BA_TERA_HISTORY: 'Riwayat BA Tera',
        AS_BUILT_DRAWING_HISTORY: 'Riwayat As Built Drawing',
        COMMISSIONING_REPORT_HISTORY: 'Riwayat Laporan Kommissioning',
        MDR_HISTORY: 'Riwayat MDR',
        SEE_HISTORY: 'Lihat Riwayat',
        DOCUMENT_NOT_AVAILABLE: 'dokumen belum tersedia',
        LIST_OF_NOT_COMPLETE_DOCUMENT: 'Daftar Dokumen Tidak Lengkap',
        MISMATCHED_ADDRESS: 'Alamat dan berkas dokumen tidak sama',
        MISMATCHED_SITEID: 'Site ID dan berkas dokumen tidak sama',
        SKHP_RENEWAL_DOCUMENT: 'Dokumen Perpanjangan SKHP'
      },
      STATUS: {
        COMPLETE: 'LENGKAP',
        NOT_COMPLETE: 'TIDAK LENGKAP',
      },
    },
    DOCUMENT_STATUS: {
      COMPLETE: 'LENGKAP',
      NOT_COMPLETE: 'TIDAK LENGKAP',
    },
    UPLOAD_STATUS: {
      UPLOADED: 'Diunggah',
      NOT_UPLOADED: 'Belum Diunggah',
      EXPIRED: 'Kedaluwarsa',
      EXPIRED_SOON: 'Akan Kedaluwarsa',
    },
    LIST_TO_ASSIGN: {
      LABEL: {
        SITE_ID: 'Site ID',
        SITE_NAME: 'Nama Site',
        DISTRICT: 'Kota/Kabupaten',
        PROVINCE: 'Provinsi',
        ASSIGN_TO_COMPANY: 'Tugaskan ke Perusahaan Inspeksi',
        PO_CODE: 'Kode PO',
        ASSIGN_LETTER: 'Surat Penugasan ke Migas',
        ASSIGN_LETTER_UPLOAD: 'Unggah Surat Penugasan ke Migas',
        UPLOAD: 'Unggah Daftar',
      },
      PLACEHOLDER: {
        SITE_ID: 'Masukkan Site ID',
        SITE_NAME: 'Masukkan Nama Site',
        DISTRICT: 'Masukkan Kota/Kabupaten',
        PROVINCE: 'Masukkan Provinsi',
        ASSIGN_TO_COMPANY: 'Tugaskan ke Perusahaan Inspeksi',
        PO_CODE: 'Masukkan PO Code',
        ASSIGN_LETTER: 'Masukkan Surat Penugasan ke Migas'
      }
    },
    SIGN_BA_INSPECTION: {
      SITE_INFO: 'Info Site',
      SITE_ID: 'Site ID',
      SITE_NAME: 'Nama Site',
      SITE_ADDRESS: 'Alamat Site',
      DISTRICT: 'Kota/Kabupaten',
      PROVINCE: 'Provinsi',
      INSPECTION_COMPANY: 'Perusahaan Inspeksi',
      STATUS: {
        COMPLETE: 'LENGKAP',
        NOT_COMPLETE: 'TIDAK LENGKAP',
      },
    },
    BA_DOCUMENT_STATUS: {
      UPLOADED: 'TERUNGGAH',
      NOT_UPLOAD: 'BELUM TERUNGGAH'
    },
    DOCUMENT_TRACKING: {
      SEND_DOCUMENT: 'Mengirim Dokumen',
      RECEIVE_BY_DISTRIBUTOR: 'Diterima oleh Distributor',
      SIGNED_BY_DISTRIBUTOR: 'Mengirim Dokumen Paraf oleh Distributor',
      RECEIVE_BY_EMLI: 'Diterima oleh EMLI',
      SIGNED_BY_EMLI: 'Mengirim Dokumen Paraf oleh EMLI',
    },
    DG_MIGAS: {
      EMPTY_DATA_MESSAGE: 'Hasil pencarian masih kosong<br>Silakan melakukan filter terlebih dahulu untuk menampilkan data',
      DOWNLOAD_XLS: 'Unduh .xls saja',
      DOWNLOAD_DOC: 'Unduh dokumen saja'
    },
    INSPECTION_BOOK_MIGAS: {
      PAGE_DESC: 'Halaman pada situs ini menampilkan Laporan Hasil Pemeriksaan Keselamatan SPBU Mini / Mikrosite sebagai bagian dari pemenuhan peraturan yang berlaku serta disampaikan kepada Direktorat Jenderal Minyak dan Gas Bumi melalui surat yang disebutkan nomor dan tanggalnya pada daftar laporan pada tampilan tabel di bawah.'
    },
    ERROR: {
      NOT_LOGGED_IN: 'Anda belum masuk',
      NOT_LOGGED_IN_MSG: 'Silakan coba masuk kembali atau jika masih bermasalah hubungi administrator.',
      UNAUTHORIZED_PAGE: 'Halaman Terlarang',
      UNAUTHORIZED_PAGE_MSG: 'Akun anda tidak mempunyai otorisasi untuk mengakses halaman ini. Silakan hubungi administrator anda untuk info lebih lanjut.',
      GET_PROFILE: 'Sistem gagal mendapatkan profile anda. Silakan hubungi staf admin.',
      FIELD_EMPTY: '{{fieldName}} tidak boleh kosong',
      MEDIA_EMPTY: 'image tidak boleh kosong',
      MISMATCHED_ERR: 'Field {{fieldName}} tidak sama dengan data mikrosite',
      MISMATCHED_ERR_COI: 'Field {{fieldName}} tidak sama dengan data mikrosite. Silakan kontak distributor.',
    },
    INSPECTION_REMARKS: {
      FROM_INSPECTION_COMPANY: 'Dari Perusahaan Inspeksi',
      FROM_DISTRIBUTOR: 'Dari Distributor',
      FROM_EMLI: 'Dari EMLI',
    },
    COMMON: {
      COMPLETE: 'Lengkap',
      NOT_COMPLETE: 'Tidak Lengkap',
      GOTO_HOME: 'Kembali ke Halaman Depan',
      GOTO_LOGIN: 'Kembali ke Halaman Login',
      DOCUMENT_LETTER_MIGAS: 'Surat Dokumen ke Migas',
      DOWNLOAD_DOCUMENT: 'Unduh Dokumen',
      LETTER_DOCUMENT: 'Dokumen Surat',
      LETTER_NUMBER: 'Nomor Surat',
      LETTER_DATE: 'Tanggal Surat',
      BA_SIGN_MIGAS_DATE: "Tanggal Tanda Tangan BA oleh Migas",
      BA_SENT_MIGAS_DATE: "Tanggal Pengiriman BA ke Migas",
      VALID: 'Berlaku',
      EXPIRED: 'Kedaluwarsa',
      EXPIRED_SOON: 'Akan Kedaluwarsa',
      ACTIVE: 'Aktif',
      SKHP_STATUS: 'Status SKHP',
      LAND_LEASE_END_DATE: 'Tanggal Berakhir Sewa Tanah',
      OPERATOR_PHONE_NO: 'Nomor Telepon Operator',
      OPERATOR_NAME: 'Nama Operator',
      PO: 'PO',
      INSPECTION_COMPANY: 'Perusahaan Inspeksi',
      INSPECTION_COMPANY_ID: 'ID Perusahaan Inspeksi',
      INSPECTION_RESULT: 'Hasil Inspeksi',
      TOTALIZER_MECHANIC: 'Penjumlah (Mekanik)',
      TOTALIZER_DIGITAL: 'Penjumlah (Digital)',
      CLOSE: 'Tutup',
      DATE_FIELD: 'Field Tanggal',
      FROM_DATE: 'Dari Tanggal',
      TO_DATE: 'Ke Tanggal',
      DATE: 'Tanggal',
      NUMBER: 'Nomor',
      MICROSITE_ID: 'ID Mikrosite',
      DISTRIBUTOR_ADDRESS: 'Alamat Distributor',
      DISTRICT_OR_CITY: 'Kota/Kabupaten',
      PROVINCE: 'Provinsi',
      OWNERSHIP_STATUS: 'Status Kepemilikan',
      LOCATION_PERMIT: 'Izin Lokasi',
      ENVIRONMENT_PERMIT: 'Izin Lingkungan',
      DOC_SAFETY: 'Dokumen Keamanan',
      NO_OF_TIMBUN_TANKS: 'Jumlah Tangki Timbun',
      CAPACITY_KL: 'Kapasitas (KL)',
      DISPENSER: 'Dispenser',
      DISTRIBUTION_AREA: 'Area Distribusi',
      TYPE_OF_FUEL_PRODUCT_DISTRIBUTED: 'Tipe Produk Bahan Bakar Terdistribusi',
      TYPE_OF_FUEL: 'Tipe Bahan Bakar',
      MICROSITE_DETAIL: 'Rincian Mikrosite',
      NO_IMAGE: 'Tidak ada gambar',
      OPERATION_STATUS: 'Status Operasi',
      REJECT_REASON: 'Alasan Penolakan',
      COMMENT: 'Komentar',
      MAJOR: 'Temuan Besar',
      MINOR: 'Temuan Kecil',
      TITLE: 'Judul',
      UPLOAD_IMAGE: 'Unggah Gambar',
      CLICK_UPLOAD_IMAGE: 'Klik Untuk Unggah gambar',
      CLICK_TO_CHOOSE_IMAGE: 'Klik untuk memilih gambar',
      UPLOAD_VIDEO: 'Unggah Video',
      CLICK_UPLOAD_VIDEO: 'Klik Untuk Unggah video',
      CLICK_TO_CHOOSE_VIDEO: 'Klik untuk memilih file video',
      SELECT_ALL_IMAGE: 'Pilih Semua Gambar',
      FILTER_BY_INSPECTION_PROGRESS: 'Filter Progres Inspeksi',
      FILTER_BY_INSPECTION_STATUS: 'Filter Status Inspeksi',
      FILTER_BY_COI_STATUS: 'Filter Status COI',
      FILTER_BY_DOC_STATUS: 'Filter Status Dokumen',
      FILTER_BY_STATUS: 'Filter Status',
      ACCESS: '{{suffix}} akses',
      UNDEFINED_ACCESS: 'Akses menu belum ditentukan',
      DEFINE_ROLE_ACCESS: 'Tentukan Akses Peran',
      EDIT_ROLE_ACCESS: 'Edit Akses Peran',
      ALL_ACCESS: 'Semua Akses',
      BACK: 'Kembali',
      CREATE: 'Buat',
      VIEW: 'Lihat',
      VIEW_FOR_REVIEW: 'Lihat untuk Tinjauan',
      ADD_IINSPECTION_DATA: 'Tambah Data Inspeksi',
      UPDATE: 'Perbarui',
      DELETE: 'Hapus',
      NO_DATA: 'Data tidak tersedia',
      NO_DATA_DISPLAY_FOR: 'Tidak ada data yang ditampilkan untuk ',
      LIST_OF_NOT_COMPLETE_DOC: 'DAFTAR DOKUMEN TIDAK LENGKAP',
      BA_INSPECTION_DOCUMENT_TRACKING: 'Lacak Dokumen BA Inspeksi',
      BA_SAFETY_INSPECTION_DOCUMENT_TRACKING: 'Lacak Dokumen BA Safety Inspeksi',
      ENTER: 'Masukkan',
      LOADING_COMPLETION_DATE: 'Tanggal Selesai Memuat',
      DOWNLOAD_FILE: 'Unduh File',
      DOCUMENT_FILE: 'File Dokumen',
      DOCUMENT_STATUS: 'Status Dokumen',
      INVALID_FORM: 'Tolong lengkapi semua bagian mandatori dengan benar',
      SITE_ID: 'Site ID',
      SITE_NAME: 'Nama Site',
      SITE_READY_TO_INSPECT: 'Site Siap Diinspeksi',
      SITE_OPERATIONAL_DATE: 'Tanggal Operasional Site',
      INSPECTION_DATE: 'Tanggal Inspeksi',
      DISTRICT: 'Kota/Kabupaten',
      STATUS: 'Status',
      PO_CODE: 'PO Code',
      UPLOAD_DOCUMENT: 'Unggah Dokumen',
      SUBMIT: 'Simpan',
      FILTER_BY_DATE: 'Filter Tanggal',
      APPLY: 'Terapkan',
      RESET: 'Reset',
      APPLY_FILTER: 'Terapkan Filter',
      START_DATE: 'Tanggal Awal',
      END_DATE: 'Tanggal Akhir',
      UPLOAD_DATE: 'Tanggal Unggah',
      SIGNATURE_DATE: 'Tanggal Paraf',
      ASSIGNMENT_LETTER: 'Surat Penugasan',
      APPOINTMENT_LETTER: 'Surat Penunjukkan',
      ASSIGNMENT_LETTER_TO_MIGAS: 'Surat Penugasan ke Migas',
      APPOINTMENT_LETTER_FROM_MIGAS: 'Surat Penunjukkan dari Migas',
      MANDATORY_IMAGES: 'Gambar Wajib',
      ADDITIONAL_IMAGES: 'Gambar Tambahan',
      OTHER_ADDITIONAL_IMAGES: 'Gambar Tambahan Lain',
      ADDITIONAL_VIDEO: 'Video Tambahan',
      VIEW_MORE: 'Lihat {{count}} Lagi',
      DELETE_DATA: 'Hapus Data',
      VIEW_DETAIL: 'Lihat Rincian',
      VIEW_DETAILS: 'Lihat Rincian',
      VIEW_DETAIL_DATA: 'Lihat Rincian Data',
      CORRECT: 'Benar',
      DATA_FAILED: '{{total}} Data Gagal',
      NEXT: 'Berikutnya',
      PREVIOUS: 'Sebelumnya',
      SAVE: 'Simpan',
      MONTH: 'Bulan',
      YEAR: 'Tahun',
      VIEW_TANK_DATA: 'Lihat Data Tangki',
      OWNERSHIP: 'Kepemilikan',
      SITE_DATA: 'Data Site',
      LEASE: 'Sewa',
      SITE_IMAGES: 'Gambar Site',
      INSPECTION_DATA: 'Data Inspeksi',
      DOCUMENT_LETTER: 'Surat Dokumen',
      REMARKS: 'Catatan',
      SYSTEM_ERROR: 'Terjadi kesalahan tak terduga saat mencoba memproses permintaan Anda. Silakan hubungi administrator sistem.',
      NOTIFICATION: 'Notifikasi',
      UNREAD: 'Belum dibaca',
      LEAVE_PAGE: 'Tinggalkan Halaman',
      STAY: 'Tetap',
      DRAG_FILES_HERE: 'Seret file ke sini',
      DRAG_TO_CHANGE: 'Klik atau seret file ke sini untuk mengganti',
      CLICK_TO_CHOOSE_FILE: 'Atau klik untuk memilih file',
      FILE: 'File:',
      DOWNLOAD_XLS_TEMPLATE: 'Unduh template file .xls',
      ARE_YOU_SURE_TO_SUBMIT: 'Anda yakin untuk submit?',
      RENEWAL_NO: 'No. Renewal',
      BY_EMAIL_SLASH_NAME: 'Oleh (Email/Nama)',
      UPLOADED_BY: 'Diunggah Oleh',
      REPORT_TYPE: 'Tipe Laporan',
      PRODUCT_TYPE: 'Tipe Produk',
      TERMINAL_SUPPLY: 'Terminal Pemasok',
      MONTHLY: 'Bulanan',
      QUARTERLY: 'Triwulanan',
      SENT: 'Dikirim',
      UNSENT: 'Belum Dikirim',
      SEND_TO_MIGAS: 'Kirim ke Migas',
      DG_MIGAS_SENT_DATE: 'Tanggal kirim DG Migas',
      COI_DATE: 'Tanggal Sertifikat Inspeksi',
      SENT_DATE: 'Tanggal Kirim',
      LOADING_COMPLETION_START_DATE: 'Tanggal Awal Selesai Memuat',
      LOADING_COMPLETION_END_DATE: 'Tanggal Akhir Selesai Memuat',
      PRODUCT: 'Produk',
      LIFTING_MODA: 'Mode Muatan',
      CHOOSE_MONTH: 'Pilih Bulan',
      CHOOSE_YEAR: 'Pilih Tahun',
      CHOOSE_PRODUCT: 'Pilih Produk',
      CHOOSE_LIFTING: 'Pilih Muatan',
      CHOOSE_UNIT: 'Pilih Unit',
      ALL_PRODUCT: 'Semua Produk',
      ALL_LIFTING: 'Semua Muatan',
      TERMINAL_NAME: 'Nama Terminal',
      FILTER_BY_PRODUCT: 'Filter Berdasarkan Produk',
      CHOOSE_TYPE: 'Pilih Tipe',
      ALL_TYPE: 'Semua Tipe',
      ALL_TERMINAL: 'Semua Terminal',
      INSPECTION_COMMENTS: 'Komentar Inspeksi',
      INSPECTION_MAJOR_COMMENT: 'Komentar Inspeksi Mayor',
      INSPECTION_MAJOR_COMMENT_CLOSE_OUT: 'Komentar Mayor Inspeksi  Ditutup',
      INSPECTION_MINOR_COMMENT: 'Komentar Inspeksi Minor',
      INSPECTION_MINOR_COMMENT_CLOSE_OUT: 'Komentar Minor Inspeksi  Ditutup',
      COMMENT_BEEN_CLOSED: 'Komentar telah ditutup',
      INSPECTION_REMARKS: 'Catatan Inspeksi',
      REVISION_COMMENT: 'Komentar Revisi',
      YES: 'Ya',
      NO: 'Tidak',
      ANSWER: 'Jawaban',
      ENTER_COMMENT: 'Masukkan Komentar',
    },
    GUIDE: {
      HOW_TO_UPLOAD_SIGN_BA_INSPECTION_DOC: 'Bagaimana cara mengunggah Paraf BA Inspection?',
      HOW_TO_UPLOAD_SIGN_BA_SAFETY_INSPECTION_DOC: 'Bagaimana cara mengunggah Paraf BA Safety Inspection?',
      DOWNLOAD_SIGN_BA_INSPECTION_DOC: 'Unduh dokumen BA Inspection, klik tombol di bawah',
      DOWNLOAD_SIGN_BA_SAFETY_INSPECTION_DOC: 'Unduh dokumen BA Safety Inspection, klik tombol di bawah',
      DOWNLOAD_BA_INSPECTION: 'Unduh BA Inspeksi',
      DOWNLOAD_BA_SAFETY_INSPECTION: 'Unduh BA Safety Inspection',
      ENTER_SIGN_INTO_DOC: 'Masukkan tanda tangan pada dokumen',
      UPLOAD_SIGNED_BA_IN_THE_UPLOAD_FIELD: 'Unggah dokumen BA Inspection yang telah ditandatangani pada bagian Unggah di samping',
      UPLOAD_SIGNED_BA_SAFETY_IN_THE_UPLOAD_FIELD: 'Unggah dokumen BA Safety Inspection yang telah ditandatangani pada bagian Unggah di samping',
      DOWNLOAD_BA_BEFORE_UPLOAD: 'Mohon Unduh dokumen BA Inspection sebelum mengunggah',
      DOWNLOAD_BA_SAFETY_BEFORE_UPLOAD: 'Mohon Unduh dokumen BA Safety Inspection sebelum mengunggah',
      LOGGED_OUT: 'Anda berhasil keluar',
    }
  },
};
