export enum MediaMandatory {
  NAME_PLATE_DISPENSER = 'NAME_PLATE_DISPENSER',
  FUEL_TANK_NUMBER = 'FUEL_TANK_NUMBER',
  FUEL_TANK_NAME_PLATE = 'FUEL_TANK_NAME_PLATE',
  TANK_UL_MANUFACTURER_NAME_PLATE = 'TANK_UL_MANUFACTURER_NAME_PLATE',
  MID = 'MID',
  APAR_DI_AREA_DISPENSER = 'APAR_DI_AREA_DISPENSER',
  APAR_DI_AREA_BENGKEL_DAN_TOKO = 'APAR_DI_AREA_BENGKEL_DAN_TOKO',
  ERP = 'ERP',
}

export enum MediaOptional {
  MICROSITE_FUEL_GENERAL_VIEW = 'MICROSITE_FUEL_GENERAL_VIEW',
  DISPENSER_AREA = 'DISPENSER_AREA',
  TERA_CALIBRATION_STICKER = 'TERA_CALIBRATION_STICKER',
  GROUNDING_CONNECTION = 'GROUNDING_CONNECTION',
  DISPENSER_MOTOR_AND_PUMP_CONDITION = 'DISPENSER_MOTOR_AND_PUMP_CONDITION',
  OIL_CATCHER = 'OIL_CATCHER',
  TITIK_BERKUMPUL = 'TITIK_BERKUMPUL',
  WARNING_SIGN = 'WARNING_SIGN',
  LAMPU_PENERANGAN = 'LAMPU_PENERANGAN',

  ABOVE_TANK = 'ABOVE_TANK',
  CLOSE_UP_ON_INDOSTATION = 'CLOSE_UP_ON_INDOSTATION',
  ELECTRICAL_PANEL_ROUTER_AND_CONTROLLER = 'ELECTRICAL_PANEL_ROUTER_AND_CONTROLLER',
  PLN_METER_INCL_TOILET = 'PLN_METER_INCL_TOILET',
  CONCRETE_BOX_FOR_USED_BOTTLES = 'CONCRETE_BOX_FOR_USED_BOTTLES',
  INFILTRATION_WELL = 'INFILTRATION_WELL'
}


export enum MediaGroup {
  MANDATORY = "MANDATORY",
  OPTIONAL = "OPTIONAL",
  OTHER = "OTHER",
  VIDEO = "VIDEO"
}
