<div class="modal-content image-viewer-modal" *ngIf="currentMedia">
  <div class="modal-header im2-title">
    <div class="d-flex gap-20px h-25px">
      <div>{{ currentMediaTitle | translate }}</div>
      <app-badge-status
        [autoContent]="true"
        [status]="currentMedia.media_tickets?.ticket_category!"
        [statusType]="MEDIA_STATUS">
      </app-badge-status>
    </div>
    <!--begin::Close-->
    <app-icon-button
      title="close"
      [iconOnly]="true"
      iconType="fa-solid"
      iconClass="fa-xmark text-primary"
      (click)="closeImageViewerModal.emit('close')"
    ></app-icon-button>
    <!--end::Close-->
  </div>
  <div class="modal-body">
    <div class="media-viewport d-flex justify-content-center">
      <div *ngIf="!currentMedia?.file_upload_id" class="align-items-center d-flex flex-column justify-content-center">
        <img
          src="../../../../../../assets/images/no-image.svg"
          />
        {{ 'COMMON.NO_IMAGE' | translate}}
      </div>
      <img *ngIf="currentMedia.file_upload_id && !isCurrentMediaVideo()"
        class="w-100"
        [src]="getImageSrc(currentMedia)"
        alt="Uploaded Image" />
      <video *ngIf="currentMedia.file_upload_id && isCurrentMediaVideo()"
        class="w-100"
        alt="Uploaded Image" controls>
        <source [src]="getThumbnailUrl(currentMedia)" />
        Your browser does not support HTML video.
        <track kind="subtitles" label="English" src="" srclang="en" default />
        <track kind="descriptions" label="Descriptions" src="" srclang="en" />
      </video>
    </div>

    <div class="text-center pt-4">{{ currentMedia.file_upload_id?.file_name }}</div>
    <div class="text-center mt-3 im2-gray-text h-40px overflow-hidden">
      {{ currentMedia.media_tickets?.comment }}
    </div>
  </div>
  <div class="modal-footer">
    <app-icon-button
      [iconType]="'fa-solid'"
      [iconName]="'fa-arrow-left'"
      (click)="previousMedia()"
      [outlined]="true"
      [title]="'COMMON.PREVIOUS'"
      [label]="'COMMON.PREVIOUS'">
    </app-icon-button>
    {{ currentPage + 1 }} / {{ data.length }}
    <app-icon-button
      [iconType]="'fa-solid'"
      [iconName]="'fa-arrow-right'"
      (click)="nextMedia()"
      [outlined]="true"
      [iconPosition]="'right'"
      [title]="'COMMON.NEXT'"
      [label]="'COMMON.NEXT'">
    </app-icon-button>
  </div>
</div>
