<div class="modal-content">
  <div class="modal-body">
    <div class="modal-title">
      {{ title }}
    </div>
    <div class="modal-text">
      {{ text }}
    </div>
    <ng-container *ngIf="validationField">
      <div class="modal-text validation-field" *ngFor="let field of validationField">
        <span class="dotted" *ngIf="field.key !== ''">. </span>
        <span class="keyfield">{{field.key}}</span>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-pill btn-secondary" (click)="closeSuccessModal.emit('close')">{{ primaryBtnTxt }}</button>
  </div>
</div>