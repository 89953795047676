export enum DataType {
  DATE = 'DATE',
  NUMBER = 'NUMBER',
}

export enum DocType {
  DOCUMENT_LICENSE = 'DOCUMENT_LICENSE',
  EMLI = 'EMLI',
  SKHP = 'SKHP'
}

export enum InspectionMediaStatus {
  MAJOR = "MAJOR",
  MINOR = "MINOR"
}

export enum ModuleName {
  MICROSITE = "MICROSITE",
  DOCUMENT_LICENSE = "DOCUMENT_LICENSE",
  INSPECTION_COMPANY = "INSPECTION_COMPANY",
  // add the rest below
}

export enum NotificationFilter {
  ALL = 'ALL',
  READ = 'READ',
  UNREAD = 'UNREAD'
}

export enum StatusTypes {
  MEDIA_STATUS = "MEDIA_STATUS",
  MICROSITE_STATUS = "MICROSITE_STATUS",
  DOCUMENT_STATUS = "DOCUMENT_STATUS",
  INSPECTION_STATUS = "INSPECTION_STATUS",
  BA_DOCUMENT_STATUS = "BA_DOCUMENT_STATUS",
  COI_STATUS = "COI_STATUS",
  UPLOAD_STATUS = "UPLOAD_STATUS",
  OPERATION_STATUS = "OPERATION_STATUS"
}

export enum UploadStatus {
  UPLOADED = "UPLOADED",
  NOT_UPLOADED = "NOT_UPLOADED",
  EXPIRED = "EXPIRED",
  EXPIRED_SOON = "EXPIRED_SOON",
}

export enum InspectionCommentStep {
  INSPECTOR = 'INSPECTOR',
  DISTRIBUTOR = 'DISTRIBUTOR',
  EMLI = 'EMLI'
}

export enum InspectionCommentFile {
  INSPECTION_COMMENT = 'INSPECTION_COMMENT'
}

export enum BAHistoriesStatus {
  INSPECTION_UPLOAD='INSPECTION_UPLOAD',
  DISTRIBUTOR_DOWNLOAD='DISTRIBUTOR_DOWNLOAD',
  DISTRIBUTOR_UPLOAD='DISTRIBUTOR_UPLOAD',
  EMLI_DOWNLOAD='EMLI_DOWNLOAD',
  EMLI_UPLOAD='EMLI_UPLOAD',
}