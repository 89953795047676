import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModalComponent } from '../common-modal.component';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['../common-modal.component.scss'],
})
export class ErrorModalComponent extends CommonModalComponent {
  
  @Output() closeErrorModal = new EventEmitter<string>();
}
