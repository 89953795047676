import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModalComponent } from '../common-modal.component';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['../common-modal.component.scss'],
})
export class SuccessModalComponent extends CommonModalComponent {
  @Output() closeSuccessModal = new EventEmitter<string>();
}
