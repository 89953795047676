<div class="modal-content">
  <div class="modal-header">
    <span class="warning-icon" inlineSVG="../../../../../../assets/icons/Content/icon-med-warning.svg"></span>
    <!--begin::Close-->
    <app-icon-button
      title="close"
      [iconOnly]="true"
      iconType="fa-solid"
      iconClass="fa-xmark text-primary"
      (click)="closeWarningModal.emit('close')"
    ></app-icon-button>
    <!--end::Close-->
  </div>
  <div class="modal-body">
    <div class="modal-title warning" >
      {{ title ?? ''  | translate }}
    </div>
    <div class="modal-text whitespace-preline" *ngIf="text" [innerHTML]="text | translate">
    </div>
    <ng-container *ngIf="validationField">
      <div class="modal-text validation-field" *ngFor="let field of validationField">
        <span class="dotted" *ngIf="field.key !== ''">. </span>
        <span class="keyfield">{{field.key}}</span>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button *ngIf="primaryBtnTxt" type="button" class="btn btn-outline btn-primary"
        (click)="closeWarningModal.emit('yes')">{{primaryBtnTxt | translate}}</button>
      <button *ngIf="secondaryBtnTxt" type="button" class="btn btn-outline btn-outline-primary"
        (click)="closeWarningModal.emit('close')">{{secondaryBtnTxt | translate}}</button>
    </ng-container>
  </div>
</div>
