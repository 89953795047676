// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  azureKey: {
    clientId: '749654e2-9cb8-44e6-8c92-a3048ff46e1a',
    authority: 'https://login.microsoftonline.com/02b4cc06-7c70-49e0-bb1a-d68d5ff7a835'
  },
  redirectUrl: 'https://exxon-microsite.sandbait.work/',
  postLogoutUrl: 'https://exxon-microsite.sandbait.work/',
  REST_API: 'https://exxon-microsite-be.sandbait.work/api/',
  appVersion: 'v8.1.8',
  msalScope: 'api://exxon-app/api',
  mapKey: {
    authType: "subscriptionKey",
    subscriptionKey: "2mTr2yrlufE4yNPyfl17gG35iEYGLp9awonYxfqcBqY0APYO3xhJJQQJ99AHAC8vTInwtgPZAAAgAZMP9Pcd"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 *
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

