import { Injectable } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { AppConstants } from './types/constants/app.constant';
import { MenuItem } from './types/interfaces/common.interface';

@Injectable()
export class Previleges {
  constructor(private authService: AuthService) {}

  public getMenuList(): MenuItem[] {
    const allowedCodes = this.getAllowedModuleCodes();
    const modules = Object.values(AppConstants.Modules);
    const allowedModules: MenuItem[] = []
    modules.forEach((m:MenuItem) => {
      if (allowedCodes?.includes(m.code)) {
        if (m.children) {
          m.children = m.children.filter(child => allowedCodes.includes(child.code))
        }
        allowedModules.push(m)
      }
    });
    return allowedModules;
  }

  public getAllowedModuleCodes(): string[] {
    const dashboardMenuList = ['dashboard_inspection_tracker', 'dashboard_cso_terminal', 'dashboard_skp_distributor'];
    const reportBuilderMenulist = ['report_builder_distributor', 'report_builder_cso', 'report_builder_inspection'];
    const modules = this.authService.getProfile()?.modules.map((m:any) => m.module_code);
    const haveDashboard = modules.some(item => dashboardMenuList.includes(item));
    const haveReportBuilder = modules.some(item => reportBuilderMenulist.includes(item));
    if (haveDashboard) modules.push('dashboard');
    if (haveReportBuilder)modules.push('report_builder');
    return modules 
  }
}
