import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, map } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { ENDPOINT } from 'src/app/types/constants/endpoint.constant';
import { IAuthProfile } from 'src/app/types/interfaces/auth-profile.interface';
import { IApiResponseDetail } from 'src/app/types/interfaces/common.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  constructor(http: HttpClient, public localStorage: LocalStorageService, private msalService: MsalService) {
    super(http);
  }

  public logout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: "/#/logout",
    });
  }
  public getProfile():IAuthProfile {
    return this.localStorage.retrieve('user_profile');
  }

  authenticateUser(): Observable<IAuthProfile> {
   return this.getRequest(ENDPOINT.PROFILE).pipe(
      map((res: IApiResponseDetail<IAuthProfile>)=>{
      const profile: IAuthProfile = res.response_output.detail;
      this.localStorage.store('user_profile', profile);
      return res.response_output.detail
    }))
  }
}
