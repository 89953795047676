import { PlatformLocation } from '@angular/common';
import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonModalComponent, CommonModalInterface } from './common-modal.component';
// import { LoaderComponent } from './loader/loader.component';
import { IMediaInfo } from 'src/app/types/interfaces/common.interface';
import { AzureMapWideComponent, MapTypes } from '../azure-map/azure-map-wide/azure-map-wide.component';
import { IPinPoint } from '../azure-map/azure-map.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { LoaderComponent } from './loader/loader.component';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { WarningModalComponent } from './warning-modal/warning-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CommonModalService {
  spinnerRef: any;
  loadingCount: number = 0;
  constructor(
    public config: NgbModalConfig,
    private readonly ngbModal: NgbModal,
    private readonly location: PlatformLocation
  ) {
    config.centered = true;
    config.backdrop = 'static';
    config.backdropClass = 'modal-overlay';
    this.location.onPopState(() => this.ngbModal.dismissAll());
  }
  public spinnerOn() {
    if (!this.spinnerRef) {
      this.loadingCount += 1;
      this.spinnerRef = this.ngbModal.open(LoaderComponent);
    } else {
      this.loadingCount += 1;
    }
  }

  public spinnerOff() {
    this.loadingCount -= 1;
    if (this.spinnerRef && this.loadingCount < 1) {
      this.spinnerRef.close();
      this.spinnerRef = undefined;
    }
  }

  public openImageViewerModal(data: IMediaInfo[], startingIndex: number) {
    const imageViewerModalRef = this.ngbModal.open(ImageViewerComponent, {size: 'xl'});
    imageViewerModalRef.componentInstance.data = data;
    imageViewerModalRef.componentInstance.currentPage = startingIndex;
    const output = imageViewerModalRef.componentInstance.closeImageViewerModal;
    output.subscribe(() => {
      imageViewerModalRef.close();
    });
    return output;
  }

  public openWarningModal(config: CommonModalInterface) {
    const warningModalRef = this.ngbModal.open(WarningModalComponent);
    warningModalRef.componentInstance.config = config;
    const output = warningModalRef.componentInstance.closeWarningModal;
    output.subscribe(() => {
      warningModalRef.close();
    });
    return output;
  }

  public openErrorModal(config: CommonModalInterface) {
    const errorModalRef = this.ngbModal.open(ErrorModalComponent);
    errorModalRef.componentInstance.config = config;
    const output = errorModalRef.componentInstance.closeErrorModal;
    output.subscribe(() => {
      errorModalRef.close();
    });
    return output;
  }

  public openSuccessModal(config: CommonModalInterface) {
    const successModalRef = this.ngbModal.open(SuccessModalComponent);
    successModalRef.componentInstance.config = config;
    const output = successModalRef.componentInstance.closeSuccessModal;
    output.subscribe(() => {
      successModalRef.close();
    });
    return output;
  }

  public openCommonModal(templateRef: TemplateRef<CommonModalComponent>) {
    return this.ngbModal.open(templateRef);
  }

  public openModalMap(zoom: number, type: MapTypes, coordinate?: IPinPoint) {
    const mapModalRef = this.ngbModal.open(AzureMapWideComponent,{size: 'xl'});
    mapModalRef.componentInstance.zoom = zoom;
    mapModalRef.componentInstance.type = type;
    mapModalRef.componentInstance.coordinate = coordinate;
    const output = mapModalRef.componentInstance.OnCloseMap;
    output.subscribe(()=>{
      mapModalRef.close();
    });
    return output;
  }

  public closeAllModal() {
    this.ngbModal.dismissAll();
  }
}
