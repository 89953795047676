<div class="modal-content">
  <div class="modal-header">
    <h3 class="m-0" *ngIf="type === 'setlocation'">{{"MICROSITE_FORM.SET_PIN_POINT" | translate}}</h3>
    <h3 class="m-0" *ngIf="type === 'view'">View Map</h3>
    <!--begin::Close-->
    <app-icon-button
      title="close"
      [iconOnly]="true"
      iconType="fa-solid"
      iconClass="fa-xmark text-primary"
      (click)="OnCloseMap.emit(null)"
    ></app-icon-button>
    <!--end::Close-->
  </div>
  <div class="modal-body">
    <div id="map-container" class="default-map-container align-items-center border rounded">
      <div id="myMapWide"></div>
    </div>
  </div>
  <div class="modal-footer" *ngIf="type === 'setlocation'">
    <div class="d-flex text-end">
      <button class="btn btn-primary d-flex flex-row align-items-center justify-content-centers"
        (click)="OnCloseMap.emit(pinPoint)">Select Pin Location</button>
    </div>
  </div>
</div>
