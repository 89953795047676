import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [TranslateModule, RouterModule],
  templateUrl: './logout.component.html',
  styles: [':host { height: 100%; width: 100%; display: block;}']
})
export class LogoutComponent implements OnInit { // This is a closing page to cleanup storage after successfully logged out from MSAL
  ngOnInit(): void {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }
}