import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as atlas from 'azure-maps-control';
import { AuthenticationType } from 'azure-maps-control';
import { SharedService } from 'src/app/services/shared.service';
import { IResponseMap } from 'src/app/types/interfaces/map.interface';
import { environment } from 'src/environments/environment';
import { IPinPoint } from '../azure-map.component';
export type MapTypes = 'view' | 'setlocation';
@Component({
  selector: 'app-azure-map-wide',
  templateUrl: './azure-map-wide.component.html',
  styleUrls: ['./azure-map-wide.component.scss'],
})
export class AzureMapWideComponent implements AfterViewInit {
  @Input() coordinate: IPinPoint | undefined;
  @Input() zoom: number = 11;
  @Input() type: MapTypes;
  pinPoint: IPinPoint;
  @Output() OnCloseMap = new EventEmitter<any>();
  defaultCoordinate = {
    latitude: -6.175581393708924,
    longitude: 106.8270943029002,
  };

  onSelectLocation() {}

  constructor(
    private translate: TranslateService,
    private sharedService: SharedService,
  ) {}

  ngAfterViewInit(): void {
      if (this.coordinate?.latitude && this.coordinate?.longitude) {
        this.getAddressFromCoordinates(
          Number(this.coordinate.latitude),
          Number(this.coordinate.longitude)
        );
      } else {
        this.getUserLocation();
      }
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.pinPoint = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          this.initializeMap(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          this.pinPoint = this.defaultCoordinate;
          this.zoom = 0;
          this.initializeMap(
            this.defaultCoordinate.latitude,
            this.defaultCoordinate.longitude
          );
          console.error(error);
        }
      );
    } else {
      this.pinPoint = this.defaultCoordinate;
      this.zoom = 0;
      this.initializeMap(
        this.defaultCoordinate.latitude,
        this.defaultCoordinate.longitude
      );
      console.error('Cannot get user location');
    }
  }

  initializeMap(lat: number, long: number) {
    const map = new atlas.Map('myMapWide', {
      center: new atlas.data.Position(long, lat),
      zoom: this.zoom,
      language: this.translate.currentLang,
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: environment.mapKey.subscriptionKey,
      },
    });

    map.controls.add(
      [
        new atlas.control.ZoomControl(),
        new atlas.control.CompassControl(),
        new atlas.control.StyleControl(),
      ],
      {
        position: atlas.ControlPosition.TopLeft,
      }
    );

    map.events.add('ready', () => {
      const datasource = new atlas.source.DataSource();
      map.sources.add(datasource);
      map.layers.add(new atlas.layer.SymbolLayer(datasource));
      datasource.add(new atlas.data.Point([long, lat]));

      if (this.type === 'setlocation') {
        map.events.add('click', (e: any) => {
          datasource.clear();
          this.pinPoint = {
            latitude: e.position[1],
            longitude: e.position[0],
          };
          datasource.add(new atlas.data.Point(e.position));
        });
      }
    });
  }

  getAddressFromCoordinates(latitude: number, longitude: number) {
    this.sharedService.searchMapByCoordinate(latitude, longitude).subscribe({
      next: (res: IResponseMap) => {
        this.pinPoint = {
          latitude: latitude,
          longitude: longitude,
        };
        this.initializeMap(latitude, longitude);
      },
      error: (err) => {
        console.error('Failed to get address by coordinate');
        this.getUserLocation();
      },
    });
  }
}
